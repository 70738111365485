import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Car } from '@app/pages/models/car';
import { from, Observable, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { CarActions } from '@app/pages/actions';


@Injectable({
    providedIn: 'root'
})
export class CarService {
    car: Car;

    constructor(
        private firestore: AngularFirestore,
        public ngFireAuth: AngularFireAuth,
        private store: Store<fromPages.State>,
    ) {

    }
    carCreate(car): Observable<any> {
        return from(this.firestore.collection('cars').add(car));
    }

    getCar(id): Observable<any> {
        return this.firestore.doc(id).snapshotChanges()
            .pipe(
                map((doc) => {
                    return doc.payload.data();
                }),
            );
    }

    getCarAll(): Observable<any> {
        return this.firestore.collection('cars').valueChanges({ idField: 'id' });
    }

    deleteCar(id): Observable<void> {
        return from(this.firestore.collection("cars").doc(id).delete());
    }
}

