
<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>Select Date</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-fab *ngIf="showFabButton" vertical="bottom" horizontal="end" slot="fixed" (click)="goToDrop(myDate)">
    <ion-fab-button color="secondary">
      <ion-icon name="arrow-forward" mode="md"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div class="flex">
    <ion-list class="width padding_top">
      <ion-item>
        <ion-label class="ion-text-center">
          <ion-text *ngIf="onward">When are you going?</ion-text>
          <ion-text *ngIf="!onward">When are you coming back?</ion-text>
          <p>Click here to select a date</p>
          <ion-icon name="arrow-down" expand="icon-only" color="secondary" class="drop"></ion-icon>
        </ion-label>
      </ion-item>

    </ion-list>

    <ion-list>
      <ion-item>
        <!-- <ion-label>Date and Time</ion-label> -->
        <ion-datetime value="" placeholder="Select Date" [(ngModel)]="myDate" min="2023" max="2025" (ionChange)="onChange()" minuteValues="0,15,30,45"></ion-datetime>
      </ion-item>
      <!-- <ion-item>
        <ion-label>Time</ion-label>
        <ion-datetime display-format="DDDD h:mm A" minuteValues="0,15,30,45" picker-format="DDDD h:mm A" value="" [(ngModel)]="myDate" (ionChange)="onChange()"></ion-datetime>
      </ion-item> -->
    </ion-list>
    

    <!-- <ion-item lines="none" class="widthCalendar">
      <ion-label>Select Date and time</ion-label>
      <ion-datetime displayFormat="D MMM YYYY H:mm" pickerFormat="MMM DD, YYYY HH:mm" min="2020" max="2025"
        minuteValues="0,15,30,45" value="" [(ngModel)]="myDate" (ionChange)="onChange()">
      </ion-datetime>
    </ion-item> -->
  </div>

</ion-content>