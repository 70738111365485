
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DataService } from '../../services/data.service';
import { LocationService } from '@app/services/location.service';
@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.page.html',
  styleUrls: ['./pickup.page.scss'],
})
export class PickupPage implements OnInit {
  autocompleteItems: any;
  item: any;
  pickupAddress: any;
  public location: any;
  searchItem = '';
  showMap = false;
  showSearchResults = false;
  usingGPS = false;
  zoom = 12;
  public markerOptions: any = { animation: google.maps.Animation.DROP }
  public mapOptions: any = {fullscreencontrol: true};


  constructor(
    private zone: NgZone,
    public dataService: DataService,
    public modalCtrl: ModalController,
    public route: Router,
    public locationService: LocationService,
  ) {
    this.location = this.locationService.pickupLocation;
    this.pickupAddress = this.locationService.pickupAddress;

    this.showMap = this.locationService.pickupAddress.value ? true : false;
  }

  ngOnInit() {
    if (!this.locationService.canUseGPS) {
      this.locationService.checkGPSPermission();
    }
  }

  async searchOnChange() {
    if (this.searchItem) {
      this.autocompleteItems = await this.locationService.getAutoCompleteResults(this.searchItem);
      this.showSearchResults = true;
    }
  }


  async chooseItem(pickup) {
    this.usingGPS = false;
    this.locationService.pickupAddress.value = pickup;
    await this.locationService.getLatLanForType(pickup, 'pickup');
    this.showMap = true;
    this.showSearchResults = false;
  }

  onFocus() {
    if (this.searchItem && this.autocompleteItems.length) {
      this.showSearchResults = true;
    }
  }
  onBlur() {
    setTimeout(() => {
      this.showSearchResults = false;
    }, 50);
  }
  onDismiss() {
    this.searchItem = '';
    this.showSearchResults = false;
  }

  async getCurrentLocation() {
    if (this.locationService.canUseGPS) {
      await this.locationService.getCurrentLocationAndAddressForType('pickup');
      this.showMap = true;
      this.usingGPS = true;
      this.searchItem = '';
    } else {
      this.locationService.checkGPSPermission();
    }
  }

  goToDrop() {
    if (this.dataService.rideType === 'offer') {
      this.route.navigate(['drop-off']);
    } else if (this.dataService.rideType === 'find') {
      this.closeModal();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
