
import { Component, NgZone, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LocationService } from '@app/services/location.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';


@Component({
  selector: 'app-drop-off',
  templateUrl: './drop-off.page.html',
  styleUrls: ['./drop-off.page.scss'],
})
export class DropOffPage implements OnInit {
  public location: any;
  public selectedRide;
  autocompleteItems: any;
  dropAddress: any;
  item: any;
  searchItem = '';
  showMap = false;
  showSearchResults = false;
  usingGPS = false;
  zoom = 12;
  public markerOptions: any = { animation: google.maps.Animation.DROP }


  constructor(
    private store: Store<fromPages.State>,
    private zone: NgZone,
    public dataService: DataService,
    public locationService: LocationService,
    public modalCtrl: ModalController,
    public route: Router,
  ) {
    this.location = this.locationService.dropLocation;
    this.dropAddress = this.locationService.dropAddress;
    this.showMap = this.locationService.dropAddress.value ? true : false;
    this.store.pipe(select(fromPages.selectSelectedRide))
      .subscribe(value => {
        this.selectedRide = { ...value };
      });
  }

  ngOnInit() {
    if (!this.locationService.canUseGPS) {
      this.locationService.checkGPSPermission();
    }
  }

  async searchOnChange() {
    if (this.searchItem) {
      this.autocompleteItems = await this.locationService.getAutoCompleteResults(this.searchItem);
      this.showSearchResults = true;
    }
  }


  async chooseItem(drop) {
    this.usingGPS = false;
    this.locationService.dropAddress.value = drop;
    await this.locationService.getLatLanForType(drop, 'drop');
    this.showMap = true;
    this.showSearchResults = false;
  }

  onFocus() {
    if (this.searchItem && this.autocompleteItems.length) {
      this.showSearchResults = true;
    }
  }
  onBlur() {
    setTimeout(() => {
      this.showSearchResults = false;
    }, 50);
  }

  onDismiss() {
    this.searchItem = '';
    this.showSearchResults = false;
  }

  async getCurrentLocation() {
    if (this.locationService.canUseGPS) {
      await this.locationService.getCurrentLocationAndAddressForType('drop');
      this.showMap = true;
      this.usingGPS = true;
      this.searchItem = '';
    } else {
      this.locationService.checkGPSPermission();
    }
  }

  goToStopOvers() {
    if (this.dataService.rideType === 'offer') {
      this.route.navigate(['stop-over']);
    } else if (this.dataService.rideType === 'find') {
      this.closeModal();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
