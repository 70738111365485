import { createAction, props } from '@ngrx/store';
import { Ride } from '@app/pages/models/ride';


//  Ride Upsert
export const rideUpsert = createAction(
  '[Ride] Upsert',
  props<{ ride: any }>()
);

export const rideUpsertSuccess = createAction(
  '[Ride] Upsert Success'
);

export const rideUpsertFailure = createAction(
  '[Ride] Upsert Failure',
  props<{ error: any }>()
);


// Ride Get
export const rideGet = createAction(
  '[Ride] Get'
);

export const rideGetSuccess = createAction(
  '[Ride] Get Success',
  props<{ rides: any }>()
);

export const rideGetFailure = createAction(
  '[Ride] Get Failure',
  props<{ error: any }>()
);


// Ride Get All
export const rideGetAll = createAction(
  '[Ride] Get All'
);

export const rideGetAllSuccess = createAction(
  '[Ride] Get All Success',
  props<{ rides: any }>()
);

export const rideGetAllFailure = createAction(
  '[Ride] Get All Failure',
  props<{ error: any }>()
);


//  Ride Delete
export const rideDelete = createAction(
  '[Ride] Delete',
  props<{ ride: any }>()
);

export const rideDeleteSuccess = createAction(
  '[Ride] Delete Success'
);

export const rideDeleteFailure = createAction(
  '[Ride] Delete Failure',
  props<{ error: any }>()
);


// Filter Get Rides Filter
export const getAllRidesFilter = createAction(
  '[Ride] Get All Rides Filter',
  props<{ rides: any[] }>()
);

export const getAllRidesFilterSuccess = createAction(
  '[Ride] Get All Rides Filter Success',
  props<{ bookedRides: any[], offeredRides: any[], rideHistory: any[] }>()
);

export const getAllRidesFilterFailure = createAction(
  '[Ride] Get All Rides Filter Failure',
  props<{ error: string }>()
);


// Filter Results 
export const filterSearchResultsSuccess = createAction(
  '[Ride] Filter Results Success',
  props<{ rides: any[] }>()
);

export const filterSearchResultsFailure = createAction(
  '[Ride] Filter Results Failure',
  props<{ error: string }>()
);


// Search Ride Update
export const searchRide = createAction(
  '[Ride] Search',
  props<{ query: string }>()
);

export const searchRideSuccess = createAction(
  '[Ride] Search Success',
  props<{ rides: any[] }>()
);

export const searchRideFailure = createAction(
  '[Ride] Search Failure',
  props<{ error: string }>()
);


// Select Ride
export const selectRide = createAction(
  '[Ride] Select',
  props<{ ride: any }>()
);


// Book Ride
export const bookRide = createAction(
  '[Ride] Book Ride',
  props<{ ride: any }>()
);

export const bookRideSuccess = createAction(
  '[Ride] Book Ride Success'
);

export const bookRideFailure = createAction(
  '[Ride] Book Ride Failure',
  props<{ error: any }>()
);


// Cancel Ride
export const cancelRide = createAction(
  '[Ride] Cancel Ride',
  props<{ data: any }>()
);

export const cancelRideSuccess = createAction(
  '[Ride] Cancel Ride Success'
);

export const cancelRideFailure = createAction(
  '[Ride] Cancel Ride Failure',
  props<{ error: any }>()
);
