
<ion-header color="secondary">
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-icon slot="end" expand="icon-only" name="arrow-back" mode="ios" color="light" (click)="closeModal()">
      </ion-icon>
    </ion-buttons>
    <ion-title>
      Messages
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #IonContent>

  <ion-list *ngFor="let item of data">
    <div *ngFor="let chat of msgList; let i = index; ">
      <ion-row *ngIf="chat.userId === User">
        <ion-col size="2">
          <div class="chatLeftAvatarImage"
            [ngClass]="(msgList[i+1] && msgList[i+1].userId === chat.userId)?'hidden':''">
            <ion-avatar class="ChatproifilePic">
              <ion-img [src]=chat.userAvatar></ion-img>
            </ion-avatar>
          </div>
        </ion-col>
        <ion-col class="right"
          [ngClass]="{'clubbed':((msgList[i+1] && msgList[i+1].userId !== chat.userId)|| !msgList[i+1])}" size="10">
          <div class="imageAvatarRight">
            <ion-label color="light">
              <div class="chatDiv" [ngClass]="{'sharper':((msgList[i+1] && msgList[i+1].userId === chat.userId) && 
                      (msgList[i-1] && msgList[i-1].userId === chat.userId)),
                      'sharper-top':(msgList[i-1] && msgList[i-1].userId === chat.userId),
                      'sharper-bottom':(msgList[i+1] && msgList[i+1].userId === chat.userId)}">
                <p class="ion-text-wrap ion-padding">{{chat.message}}
                </p>
              </div>
              <div class="timedivRight" *ngIf="((msgList[i+1] && msgList[i+1].userId !== chat.userId)|| !msgList[i+1])">
                <ion-text class="footer-text-size">{{chat.time + '&nbsp;&nbsp;' + chat.name}}</ion-text>
              </div>
            </ion-label>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="chat.userId === toUser" align-item-center>
        <ion-col class="left ion-no-padding"
          [ngClass]="{'clubbed':((msgList[i+1] && msgList[i+1].userId !== chat.userId)|| !msgList[i+1])}" size="10">
          <div class="imageAvatarLeft">
            <ion-label color="light">
              <div class="chatDiv" [ngClass]="{'sharper':((msgList[i+1] && msgList[i+1].userId === chat.userId) && 
                      (msgList[i-1] && msgList[i-1].userId === chat.userId)),
                      'sharper-top':(msgList[i-1] && msgList[i-1].userId === chat.userId),
                      'sharper-bottom':(msgList[i+1] && msgList[i+1].userId === chat.userId)}">
                <p class="ion-text-wrap ion-padding" *ngIf="!chat.image && !chat.video && !chat.map">{{chat.message}}
                </p>
              </div>
              <div class="timediv" *ngIf="((msgList[i+1] && msgList[i+1].userId !== chat.userId)|| !msgList[i+1])">
                <ion-icon [name]="chat.status" color="rose"></ion-icon>
                <ion-text color="rose" class="footer-text-size">{{chat.time}}</ion-text>
              </div>
            </ion-label>
          </div>
        </ion-col>
        <ion-col size="2">
          <div class="chatLeftAvatarImage"
            [ngClass]="(msgList[i+1] && msgList[i+1].userId === chat.userId)?'hidden':''">
            <ion-avatar class="ChatproifilePic">
              <ion-img src="assets/chat/chat3.jpg"></ion-img>
            </ion-avatar>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="((msgList[i+1] && msgList[i+1].userId !== chat.userId)|| !msgList[i+1])">
        <ion-col>
        </ion-col>
      </ion-row>
    </div>
    <ion-row *ngIf="loader" padding-bottom>
      <ion-col no-padding class="loading-col">
        <div class="imageAvatarRight">
          <div class="imageAvatarBottomLoader">
            <ion-avatar class="avatar">
              <div class="imageAvatarBottomIcon">
                <ion-icon name="add" expand="icon-only" color="light"> </ion-icon>
              </div>
              <ion-img src="assets/chat/chat5.jpg"></ion-img>
            </ion-avatar>
          </div>
          <ion-label>
            <div class="chatDivLoader">
              <ion-spinner name="dots" color="light"></ion-spinner>
            </div>
          </ion-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-item class="footerItem">
      <ion-input (ionFocus)="focusFunction($event)" [(ngModel)]="userInput" (keypress)="userTyping($event)" type="text"
        placeholder="Type your message here..."></ion-input>
      <ion-icon name="send" expand="icon-only" color="medium" mode="ios" slot="end" (click)=" sendMsg()"
        (mousedown)="something($event)">
      </ion-icon>
      <ion-icon name="link" expand="icon-only" color="medium" mode="ios" slot="start" (click)="toggleList('show')">
      </ion-icon>
    </ion-item>
  </ion-toolbar>
</ion-footer>