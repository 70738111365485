import { createAction, props } from '@ngrx/store';
import { User } from '@app/pages/models/user';


// User
export const userGet = createAction(
    '[User Page] Get User'
);

export const userGetSuccess = createAction(
    '[User Page] Get User Success',
    props<{ user: any }>()
);

export const userGetFailure = createAction(
    '[User Page] Get User Failure',
    props<{ error: any }>()
);


// All Users
export const userGetAll = createAction(
    '[User Page] Get All Users',
);

export const userGetAllSuccess = createAction(
    '[User Page] Get All Users Success',
    props<{ users: any[] }>()
);

export const userGetAllFailure = createAction(
    '[User Page] Get All Users Failure',
    props<{ error: any }>()
);


// User Upsert
export const userUpsert = createAction(
    '[User Page] User Upsert',
    props<{ user: User }>()
);

export const userUpsertSuccess = createAction(
    '[User Page] User Upsert Success',
    props<{ user: any }>()

);

export const userUpsertFailure = createAction(
    '[User Page] User Upsert Failure',
    props<{ error: any }>()
);


// User Update
export const userUpdate = createAction(
    '[User Page] User Update',
    props<{ data: any }>()
);

export const userUpdateSuccess = createAction(
    '[User Page] User Update Success',
    props<{ user: User }>()
);

export const userUpdateFailure = createAction(
    '[User Page] User Update Failure',
    props<{ error: any }>()
);

// User Sign Up
export const userSignUp = createAction(
    '[User Page] User Sign Up',
    props<{ data: any }>()
);


// Notification Settings
export const updateNotificationSettings = createAction(
    '[Notification Settings] Update',
    props<{ user: User }>()
);

export const updateNotificationSettingsSuccess = createAction(
    '[Notification Settings] Update Success',
    props<{ user: User }>()
);

export const updateNotificationSettingsFailure = createAction(
    '[Notification Settings] Update Failure',
    props<{ error: any }>()
);