
<ion-header *ngIf="dataService.currentTab==='home'">
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button *ngIf="dataService.rideType!=='find'">
        <ion-back-button text=""></ion-back-button>
      </ion-button>
      <ion-button *ngIf="dataService.rideType==='find'" (click)="closeModal()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <div class="content">
    <div class="content-list">
  <ion-item>
    <h1>Pick-up</h1>
  </ion-item>
  <ion-searchbar placeholder="e.g Atlanta, Georgia" class="background-transparent" [(ngModel)]="searchItem"
    showCancelButton="always" debounce="500" (ionFocus)="onFocus()" (ionInput)="searchOnChange()"
    (ionCancel)="onDismiss()" (ionBlur)="onBlur()" selected="true" autofocus="true">
  </ion-searchbar>

  <ion-card *ngIf="searchItem !== '' && showSearchResults">
    <ion-list style="box-shadow: 0 0px 6px #bdbdbd!important;">
      <ion-item *ngFor="let item of autocompleteItems" class="item-request-ride" button="true"
        (click)="chooseItem(item)">
        <ion-icon name="location-outline" slot="start" color="secondary"></ion-icon>
        <ion-label>
          <h2>{{item}}</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
  <ion-item class="item-request-ride" button="true" (click)="getCurrentLocation()">
    <ion-icon *ngIf="!usingGPS" name="location-outline" slot="start" color="secondary"></ion-icon>
    <ion-icon *ngIf="usingGPS" name="location" slot="start" color="secondary"></ion-icon>
    <ion-label>
      <h2>{{usingGPS?'Using':'Use'}} current location</h2>
    </ion-label>
  </ion-item>

  <google-map *ngIf="showMap" [center]="location.center" [zoom]="zoom" [options]="mapOptions" height="50vh" width="100vw">
    <map-marker [position]="location.center" markerDraggable="true"
      [options]="markerOptions">
      <map-info-window>
        <strong>Info Window content</strong>
      </map-info-window>
    </map-marker>
  </google-map>
  <div class="map-gap">
  </div>
  <ion-grid *ngIf="pickupAddress && pickupAddress.value">
    <ion-row>
      <ion-col>
        <ion-text class="label-small">Pickup Address</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="10">
        <ion-text class="lighter">{{pickupAddress.value}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-fab *ngIf="pickupAddress.value" vertical="bottom" horizontal="end" slot="fixed" (click)="goToDrop()">
    <ion-fab-button color="secondary">
      <ion-icon name="arrow-forward" mode="md"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  </div>
</div>

</ion-content>