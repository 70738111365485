
import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Camera } from '@capacitor/camera';
import { Geolocation } from '@capacitor/geolocation';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShareableModule } from './components/shareable/shareable.module';
import { StopOverLocationPageModule } from '@app/pages/stop-over-location/stop-over-location.module';
import { PickupPageModule } from '@app/pages/pickup/pickup.module';
import { DropOffPageModule } from '@app/pages/drop-off/drop-off.module';
import { CalendarPageModule } from '@app/pages/calendar/calendar.module';
import { TabsPageModule } from './pages/tabs/tabs.module';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { LocationAccuracy } from '@awesome-cordova-plugins//location-accuracy';
import {
  RouterEffects
} from '@app/pages/effects';

import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFirestoreModule,
  SETTINGS,
} from '@angular/fire/compat/firestore';

import { environment } from '@env/environment';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { PagesModule } from '@app/pages';


import { ROOT_REDUCERS, metaReducers } from '@app/reducers';

// import { CoreModule } from '@app/core';
// import {
//   UserEffects,
//   RouterEffects
// } from '@app/core/effects';
import { HttpClientModule } from '@angular/common/http';
import { Calendar } from '@awesome-cordova-plugins/calendar';


@NgModule({
  declarations: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ShareableModule,
    GoogleMapsModule,
    TabsPageModule,
    PickupPageModule,
    DropOffPageModule,
    CalendarPageModule,
    StopOverLocationPageModule,

    // Angular Firestore
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,

    PagesModule,
    HttpClientModule,

    /**
 * StoreModule.forRoot is imported once in the root module, accepting a reducer
 * function or object map of reducer functions. If passed an object of
 * reducers, combineReducers will be run creating your application
 * meta-reducer. This returns all providers for an @ngrx/store
 * based application.
 */
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'HereThere',

      // In a production build you would want to disable the Store Devtools
      // logOnly: environment.production,
    }),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://ngrx.io/guide/effects#registering-root-effects
     */
    EffectsModule.forRoot([RouterEffects]),
    // CoreModule,
  ],
  providers: [
    {
      provide: SETTINGS,
      useValue: { timestampsInSnapshot: true, merge: true },
    },
    { provide: Window, useValue: window },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
