import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { UserClass, Credentials } from '@app/pages/models/user'
import { UserPageActions, LoginFormPageActions, RidePageActions, EditProfilePageActions, CarActions } from '@app/pages/actions';
import { UserService } from '@app/pages/services/user.service';
import { UtilService } from '@app/pages/services/util.service';
import * as fromPages from '@app/pages/reducers';
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import { } from '@angular/fire/compat/auth';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private copyUser;
  private isLoggedInValue: boolean = false;
  private stopLoop: boolean = false;
  private user;
  private userData: any;
  private confirmationResult: any;

  constructor(
    private http: HttpClient,
    private store: Store<fromPages.State>,
    private userService: UserService,
    private utilService: UtilService,
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public ngZone: NgZone,
    public router: Router,
  ) {
    this.store.pipe(
      select(fromPages.selectLoggedIn),
      map(value => { this.isLoggedInValue = value }),
      take(1)
    );

    this.ngFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));

        if (!this.stopLoop) {
          this.copyUser = this.utilService.shallowCopyObject(user, UserClass);
          this.store.dispatch(LoginFormPageActions.loginSuccess({ user: this.copyUser }));
          this.store.dispatch(UserPageActions.userGetSuccess({ user: this.copyUser }));
          this.stopLoop = true;
        }
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });

    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        if (value) {
          this.user = { ...value };
        }
      });
  }

  // Login in with email/password
  SignIn({ username, password }: Credentials): Observable<any> {
    return from(this.ngFireAuth.signInWithEmailAndPassword(username, password))
      .pipe(
        map((doc) => {
          let userCopy: any;
          const newUser = new UserClass();
          const keys = Object.keys(newUser);;
          keys.forEach((prop: any) => {
            newUser[prop] = doc.user[prop] ? doc.user[prop] : newUser[prop];
          })
          userCopy = { ...newUser };

          return userCopy;
        }),
      );
  }

  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password);
  }

  // Email verification when new user register
  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then((user) => {
      return user.sendEmailVerification().then(() => {
        this.router.navigate(['tabs/home']);
      });
    });
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert(
          'Password reset email has been sent, please check your inbox.'
        );
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.emailVerified !== false ? true : false;
  }

  // Sign in with Gmail
  GoogleAuth() {
    // return this.AuthLogin(new googleAuth.GoogleAuthProvider());
  }

  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.userService.updateUser(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Sign-out
  Logout(): Observable<any> {
    return from(this.ngFireAuth.signOut()
      .then(() => {
        localStorage.removeItem('user');
        this.router.navigate(['login']);
      })
      .catch((error) => {
        return error.message;
      }));
  }

  public signInWithPhoneNumber(recaptchaVerifier, phoneNumber) {
    return new Promise<any>((resolve, reject) => {

      this.ngFireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((result: any) => {
          this.confirmationResult = result;
          resolve(result);
        }).catch((error) => {
          console.log(error);
          reject('SMS not sent');
        });
    });
  }

  public async enterVerificationCode(code) {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult.confirm(code).then(async (result) => {
        let data = { phoneNumberVerified: true };
        const user = result.user;
        this.store.dispatch(UserPageActions.userUpdate({ data }));
        resolve(user);
      }).catch((error) => {
        reject(error.message);
      });

    });
  }

  checkVerifiedEmail(data) {
    if (data.user.emailVerified) {
      this.router.navigate(['/tabs/home'])
    } else {
      this.router.navigate(['/verify-email'])
    }
  }

  async canActivate(): Promise<Observable<boolean>> {
    return await this.store.pipe(
      select(fromPages.selectLoggedIn),
      map(authed => {
        if (!authed) {
          this.store.dispatch(LoginFormPageActions.loginRedirect());
          // this.router.navigate(['/login']);

          return false;
        }

        return true;
      })
          );
  }
}