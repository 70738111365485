// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyCBZzMY_tdXvHSmLBu7_37DFZAuzkIpDa0",
    authDomain: "herethere-c5b49.firebaseapp.com",
    projectId: "herethere-c5b49",
    storageBucket: "herethere-c5b49.appspot.com",
    messagingSenderId: "85815072592",
    appId: "1:85815072592:web:da3a3e2627d2589278fe55",
    measurementId: "G-MLKWDEZP9Q",
    vapidKey: 'BOPF-nyBxghFrLL-VfIS7963iJX9CoT2aT9ItVZelxyJcT4eQXid2awJlZ_YqiwFPXFScF7Z-8Dtg12fNt8en6Y',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyCxGkNCXnf5paajRdFmmzE-jCtK86PUHkU',
  FUNCTIONS_BASE_URL_LOCAL: 'http://127.0.0.1:5001/herethere-c5b49/us-central1',
  FUNCTIONS_BASE_URL: 'https://us-central1-herethere-c5b49.cloudfunctions.net',
  country: 'US',
  CHAT_DATA: [{
    name: 'Enappd',
    image: 'assets/chat/herethere.png',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    count: '2',
    time: '11:32 PM',

    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png'

  }, {
    name: 'Jovenica Alba',
    image: 'assets/chat/chat1.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    count: '2',
    time: '12:17',
    distance: '6km',
    type: 'PRO',
    crown: 'assets/chat/crown.png',


  }, {
    name: 'Oliver',
    image: ' assets/chat/chat2.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: '12:17',
    distance: '6km',
    type: 'Basic',
    crown: 'assets/chat/crown1.png',

  }, {
    name: 'George',
    image: ' assets/chat/chat3.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    count: '2',
    time: 'Yesterday',
    distance: '6km',
    type: 'Basic',
    crown: 'assets/chat/crown1.png',

  }, {
    name: 'Harry',
    image: ' assets/chat/chat3.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: 'Sunday',
    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png',

  }, {
    name: 'Jack',
    image: ' assets/chat/chat5.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',

    time: '11:15',
    distance: '6km',
    type: 'Basic',
    crown: 'assets/chat/crown1.png',

  }, {
    name: 'Jacob',
    image: ' assets/chat/chat6.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    count: '1',
    time: 'Yesterday',
    distance: '6km',
    type: 'Basic',
    crown: 'assets/chat/crown1.png',

  }, {
    name: 'Noah',
    image: ' assets/chat/chat7.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: 'Monday',
    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png',

  }, {
    name: 'Charlie',
    image: ' assets/chat/chat8.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    count: '6',
    time: '07:00',
    distance: '6km',
    type: 'Basic',
    crown: 'assets/chat/crown1.png',

  }, {
    name: 'Logan',
    image: ' assets/chat/chat1.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: 'Yesterday',
    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png',

  }, {
    name: 'Harrison',
    image: ' assets/chat/chat2.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',

    time: 'Yesterday',
    distance: '6km',
    type: 'Basic', crown: 'assets/chat/crown1.png',

  }, {
    name: 'Sebastian',
    image: ' assets/chat/chat3.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',

    time: 'Yesterday',
    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png'
  }, {
    name: 'Zachary',
    image: ' assets/chat/chat3.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: 'Today',
    distance: '6km',
    type: 'Basic', crown: 'assets/chat/crown1.png',

  }, {
    name: 'Elijah',
    image: ' assets/chat/chat5.jpg',
    description: ' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim laboriosam sunt nulla minima ratione, pariatur quaerat aut ex a ullam? Officia, explicabo optio. Dolores, ab exercitationem? Neque illo soluta sapiente!',
    time: '18:25',
    distance: '6km',
    type: 'PRO', crown: 'assets/chat/crown.png',

  }],
  DELETE_RIDE: [{
    header1: 'Are you sure you want to delete this ride offer?',
    header2: 'Please tell us what happened.it\'ll help us improve our service.',
    listheader: 'Your reason',
    textarea: false,
  },
  {
    label: 'Reason',
    select: [{ reason: 'Something cameup, i am no longer offering-this ride' },
    { reason: 'The person who turned up was\'t the person who booked' },
    { reason: 'I have to cahnge my departure time.' },
    { reason: 'I have to change the schedule.' },
    { reason: 'I want to change the price per seat.' },
    { reason: 'My car broke down.' },
    { reason: 'I have to edit my ride offer.' },
    { reason: 'Another reason that is not in the list' },
    ],
    textarea: false,
  },
  {
    label: 'MORE DETAILS',
    placeholder: 'Please provide details(these will only be seen by our Community Relations team).',
    textarea: true,
  },
  {
    header1: 'Note: Your cancellation rate is recorded on your profile.',
    header2: 'Any co-traveller wil be automatically notified.it\'d be nice touch if you also let them know directly. ',
    textarea: false,
  }],
  CAR_DATA: [{
    name: 'Madza',
    car_no: '43A 235 70',
    icon: 'newspaper-outline',
  },
  {
    name: 'Mitshubishi Outlander',
    car_no: '43A 125 70',
    icon: 'newspaper-outline',
  }],
  NEW_VECHILE_DATA: [
    {
      label: 'Make',
      placeholder: 'Select Vehicle',
      data: [
      
          {
            "name": "Alfa Romeo",
            "models": [
              "145",
              "146",
              "147",
              "155",
              "156",
              "156 Sportwagon",
              "159",
              "159 Sportwagon",
              "164",
              "166",
              "4C",
              "Brera",
              "GTV",
              "MiTo",
              "Crosswagon",
              "Spider",
              "GT",
              "Giulietta",
              "Giulia"
            ]
          },
          {
            "name": "Audi",
            "models": [
              "100",
              "100 Avant",
              "80",
              "80 Avant",
              "80 Cabrio",
              "90",
              "A1",
              "A2",
              "A3",
              "A3 Cabriolet",
              "A3 Limuzina",
              "A3 Sportback",
              "A4",
              "A4 Allroad",
              "A4 Avant",
              "A4 Cabriolet",
              "A5",
              "A5 Cabriolet",
              "A5 Sportback",
              "A6",
              "A6 Allroad",
              "A6 Avant",
              "A7",
              "A8",
              "A8 Long",
              "Q3",
              "Q5",
              "Q7",
              "R8",
              "RS4 Cabriolet",
              "RS4/RS4 Avant",
              "RS5",
              "RS6 Avant",
              "RS7",
              "S3/S3 Sportback",
              "S4 Cabriolet",
              "S4/S4 Avant",
              "S5/S5 Cabriolet",
              "S6/RS6",
              "S7",
              "S8",
              "SQ5",
              "TT Coupé",
              "TT Roadster",
              "TTS"
            ]
          },
          {
            "name": "BMW",
            "models": [
              "i3",
              "i8",
              "M3",
              "M4",
              "M5",
              "M6",
              "Rad 1",
              "Rad 1 Cabrio",
              "Rad 1 Coupé",
              "Rad 2",
              "Rad 2 Active Tourer",
              "Rad 2 Coupé",
              "Rad 2 Gran Tourer",
              "Rad 3",
              "Rad 3 Cabrio",
              "Rad 3 Compact",
              "Rad 3 Coupé",
              "Rad 3 GT",
              "Rad 3 Touring",
              "Rad 4",
              "Rad 4 Cabrio",
              "Rad 4 Gran Coupé",
              "Rad 5",
              "Rad 5 GT",
              "Rad 5 Touring",
              "Rad 6",
              "Rad 6 Cabrio",
              "Rad 6 Coupé",
              "Rad 6 Gran Coupé",
              "Rad 7",
              "Rad 8 Coupé",
              "X1",
              "X3",
              "X4",
              "X5",
              "X6",
              "Z3",
              "Z3 Coupé",
              "Z3 Roadster",
              "Z4",
              "Z4 Roadster"
            ]
          },
          {
            "name": "Chevrolet",
            "models": [
              "Alero",
              "Aveo",
              "Camaro",
              "Captiva",
              "Corvette",
              "Cruze",
              "Cruze SW",
              "Epica",
              "Equinox",
              "Evanda",
              "HHR",
              "Kalos",
              "Lacetti",
              "Lacetti SW",
              "Lumina",
              "Malibu",
              "Matiz",
              "Monte Carlo",
              "Nubira",
              "Orlando",
              "Spark",
              "Suburban",
              "Tacuma",
              "Tahoe",
              "Trax"
            ]
          },
          {
            "name": "Chrysler",
            "models": [
              "300 C",
              "300 C Touring",
              "300 M",
              "Crossfire",
              "Grand Voyager",
              "LHS",
              "Neon",
              "Pacifica",
              "Plymouth",
              "PT Cruiser",
              "Sebring",
              "Sebring Convertible",
              "Stratus",
              "Stratus Cabrio",
              "Town & Country",
              "Voyager"
            ]
          },
          {
            "name": "Citroën",
            "models": [
              "Berlingo",
              "C-Crosser",
              "C-Elissée",
              "C-Zero",
              "C1",
              "C2",
              "C3",
              "C3 Picasso",
              "C4",
              "C4 Aircross",
              "C4 Cactus",
              "C4 Coupé",
              "C4 Grand Picasso",
              "C4 Sedan",
              "C5",
              "C5 Break",
              "C5 Tourer",
              "C6",
              "C8",
              "DS3",
              "DS4",
              "DS5",
              "Evasion",
              "Jumper",
              "Jumpy",
              "Saxo",
              "Nemo",
              "Xantia",
              "Xsara"
            ]
          },
          {
            "name": "Dacia",
            "models": [
              "Dokker",
              "Duster",
              "Lodgy",
              "Logan",
              "Logan MCV",
              "Logan Van",
              "Sandero",
              "Solenza"
            ]
          },
          {
            "name": "Daewoo",
            "models": [
              "Espero",
              "Kalos",
              "Lacetti",
              "Lanos",
              "Leganza",
              "Lublin",
              "Matiz",
              "Nexia",
              "Nubira",
              "Nubira kombi",
              "Racer",
              "Tacuma",
              "Tico"
            ]
          },
          {
            "name": "Dodge",
            "models": [
              "Avenger",
              "Caliber",
              "Challenger",
              "Charger",
              "Grand Caravan",
              "Journey",
              "Magnum",
              "Nitro",
              "RAM",
              "Stealth",
              "Viper"
            ]
          },
          {
            "name": "Fiat",
            "models": [
              "1100",
              "126",
              "500",
              "500L",
              "500X",
              "850",
              "Barchetta",
              "Brava",
              "Cinquecento",
              "Coupé",
              "Croma",
              "Doblo",
              "Doblo Cargo",
              "Doblo Cargo Combi",
              "Ducato",
              "Ducato Van",
              "Ducato Kombi",
              "Ducato Podvozok",
              "Florino",
              "Florino Combi",
              "Freemont",
              "Grande Punto",
              "Idea",
              "Linea",
              "Marea",
              "Marea Weekend",
              "Multipla",
              "Palio Weekend",
              "Panda",
              "Panda Van",
              "Punto",
              "Punto Cabriolet",
              "Punto Evo",
              "Punto Van",
              "Qubo",
              "Scudo",
              "Scudo Van",
              "Scudo Kombi",
              "Sedici",
              "Seicento",
              "Stilo",
              "Stilo Multiwagon",
              "Strada",
              "Talento",
              "Tipo",
              "Ulysse",
              "Uno",
              "X1/9"
            ]
          },
          {
            "name": "Ford",
            "models": [
              "Aerostar",
              "B-Max",
              "C-Max",
              "Cortina",
              "Cougar",
              "Edge",
              "Escort",
              "Escort Cabrio",
              "Escort kombi",
              "Explorer",
              "F-150",
              "F-250",
              "Fiesta",
              "Focus",
              "Focus C-Max",
              "Focus CC",
              "Focus kombi",
              "Fusion",
              "Galaxy",
              "Grand C-Max",
              "Ka",
              "Kuga",
              "Maverick",
              "Mondeo",
              "Mondeo Combi",
              "Mustang",
              "Orion",
              "Puma",
              "Ranger",
              "S-Max",
              "Sierra",
              "Street Ka",
              "Tourneo Connect",
              "Tourneo Custom",
              "Transit",
              "Transit",
              "Transit Bus",
              "Transit Connect LWB",
              "Transit Courier",
              "Transit Custom",
              "Transit kombi",
              "Transit Tourneo",
              "Transit Valnik",
              "Transit Van",
              "Transit Van 350",
              "Windstar"
            ]
          },
          {
            "name": "Honda",
            "models": [
              "Accord",
              "Accord Coupé",
              "Accord Tourer",
              "City",
              "Civic",
              "Civic Aerodeck",
              "Civic Coupé",
              "Civic Tourer",
              "Civic Type R",
              "CR-V",
              "CR-X",
              "CR-Z",
              "FR-V",
              "HR-V",
              "Insight",
              "Integra",
              "Jazz",
              "Legend",
              "Prelude"
            ]
          },
          {
            "name": "Hummer",
            "models": [
              "H2",
              "H3"
            ]
          },
          {
            "name": "Hyundai",
            "models": [
              "Accent",
              "Atos",
              "Atos Prime",
              "Coupé",
              "Elantra",
              "Galloper",
              "Genesis",
              "Getz",
              "Grandeur",
              "H 350",
              "H1",
              "H1 Bus",
              "H1 Van",
              "H200",
              "i10",
              "i20",
              "i30",
              "i30 CW",
              "i40",
              "i40 CW",
              "ix20",
              "ix35",
              "ix55",
              "Lantra",
              "Matrix",
              "Santa Fe",
              "Sonata",
              "Terracan",
              "Trajet",
              "Tucson",
              "Veloster"
            ]
          },
          {
            "name": "Infiniti",
            "models": [
              "EX",
              "FX",
              "G",
              "G Coupé",
              "M",
              "Q",
              "QX"
            ]
          },
          {
            "name": "Jaguar",
            "models": [
              "Daimler",
              "F-Pace",
              "F-Type",
              "S-Type",
              "Sovereign",
              "X-Type",
              "X-type Estate",
              "XE",
              "XF",
              "XJ",
              "XJ12",
              "XJ6",
              "XJ8",
              "XJ8",
              "XJR",
              "XK",
              "XK8 Convertible",
              "XKR",
              "XKR Convertible"
            ]
          },
          {
            "name": "Jeep",
            "models": [
              "Cherokee",
              "Commander",
              "Compass",
              "Grand Cherokee",
              "Patriot",
              "Renegade",
              "Wrangler"
            ]
          },
          {
            "name": "Kia",
            "models": [
              "Avella",
              "Besta",
              "Carens",
              "Carnival",
              "Cee`d",
              "Cee`d SW",
              "Cerato",
              "K 2500",
              "Magentis",
              "Opirus",
              "Optima",
              "Picanto",
              "Pregio",
              "Pride",
              "Pro Cee`d",
              "Rio",
              "Rio Combi",
              "Rio sedan",
              "Sephia",
              "Shuma",
              "Sorento",
              "Soul",
              "Sportage",
              "Venga"
            ]
          },
          {
            "name": "Land Rover",
            "models": [
              "109",
              "Defender",
              "Discovery",
              "Discovery Sport",
              "Freelander",
              "Range Rover",
              "Range Rover Evoque",
              "Range Rover Sport"
            ]
          },
          {
            "name": "Lexus",
            "models": [
              "CT",
              "GS",
              "GS 300",
              "GX",
              "IS",
              "IS 200",
              "IS 250 C",
              "IS-F",
              "LS",
              "LX",
              "NX",
              "RC F",
              "RX",
              "RX 300",
              "RX 400h",
              "RX 450h",
              "SC 430"
            ]
          },
          {
            "name": "MINI",
            "models": [
              "Cooper",
              "Cooper Cabrio",
              "Cooper Clubman",
              "Cooper D",
              "Cooper D Clubman",
              "Cooper S",
              "Cooper S Cabrio",
              "Cooper S Clubman",
              "Countryman",
              "Mini One",
              "One D"
            ]
          },
          {
            "name": "Mazda",
            "models": [
              "121",
              "2",
              "3",
              "323",
              "323 Combi",
              "323 Coupé",
              "323 F",
              "5",
              "6",
              "6 Combi",
              "626",
              "626 Combi",
              "B-Fighter",
              "B2500",
              "BT",
              "CX-3",
              "CX-5",
              "CX-7",
              "CX-9",
              "Demio",
              "MPV",
              "MX-3",
              "MX-5",
              "MX-6",
              "Premacy",
              "RX-7",
              "RX-8",
              "Xedox 6"
            ]
          },
          {
            "name": "Mercedes-Benz",
            "models": [
              "100 D",
              "115",
              "124",
              "126",
              "190",
              "190 D",
              "190 E",
              "200 - 300",
              "200 D",
              "200 E",
              "210 Van",
              "210 kombi",
              "310 Van",
              "310 kombi",
              "230 - 300 CE Coupé",
              "260 - 560 SE",
              "260 - 560 SEL",
              "500 - 600 SEC Coupé",
              "Trieda A",
              "A",
              "A L",
              "AMG GT",
              "Trieda B",
              "Trieda C",
              "C",
              "C Sportcoupé",
              "C T",
              "Citan",
              "CL",
              "CL",
              "CLA",
              "CLC",
              "CLK Cabrio",
              "CLK Coupé",
              "CLS",
              "Trieda E",
              "E",
              "E Cabrio",
              "E Coupé",
              "E T",
              "Trieda G",
              "G Cabrio",
              "GL",
              "GLA",
              "GLC",
              "GLE",
              "GLK",
              "Trieda M",
              "MB 100",
              "Trieda R",
              "Trieda S",
              "S",
              "S Coupé",
              "SL",
              "SLC",
              "SLK",
              "SLR",
              "Sprinter"
            ]
          },
          {
            "name": "Mitsubishi",
            "models": [
              "3000 GT",
              "ASX",
              "Carisma",
              "Colt",
              "Colt CC",
              "Eclipse",
              "Fuso canter",
              "Galant",
              "Galant Combi",
              "Grandis",
              "L200",
              "L200 Pick up",
              "L200 Pick up Allrad",
              "L300",
              "Lancer",
              "Lancer Combi",
              "Lancer Evo",
              "Lancer Sportback",
              "Outlander",
              "Pajero",
              "Pajeto Pinin",
              "Pajero Pinin Wagon",
              "Pajero Sport",
              "Pajero Wagon",
              "Space Star"
            ]
          },
          {
            "name": "Nissan",
            "models": [
              "100 NX",
              "200 SX",
              "350 Z",
              "350 Z Roadster",
              "370 Z",
              "Almera",
              "Almera Tino",
              "Cabstar E - T",
              "Cabstar TL2 Valnik",
              "e-NV200",
              "GT-R",
              "Insterstar",
              "Juke",
              "King Cab",
              "Leaf",
              "Maxima",
              "Maxima QX",
              "Micra",
              "Murano",
              "Navara",
              "Note",
              "NP300 Pickup",
              "NV200",
              "NV400",
              "Pathfinder",
              "Patrol",
              "Patrol GR",
              "Pickup",
              "Pixo",
              "Primastar",
              "Primastar Combi",
              "Primera",
              "Primera Combi",
              "Pulsar",
              "Qashqai",
              "Serena",
              "Sunny",
              "Terrano",
              "Tiida",
              "Trade",
              "Vanette Cargo",
              "X-Trail"
            ]
          },
          {
            "name": "Opel",
            "models": [
              "Agila",
              "Ampera",
              "Antara",
              "Astra",
              "Astra cabrio",
              "Astra caravan",
              "Astra coupé",
              "Calibra",
              "Campo",
              "Cascada",
              "Corsa",
              "Frontera",
              "Insignia",
              "Insignia kombi",
              "Kadett",
              "Meriva",
              "Mokka",
              "Movano",
              "Omega",
              "Signum",
              "Vectra",
              "Vectra Caravan",
              "Vivaro",
              "Vivaro Kombi",
              "Zafira"
            ]
          },
          {
            "name": "Peugeot",
            "models": [
              "1007",
              "107",
              "106",
              "108",
              "2008",
              "205",
              "205 Cabrio",
              "206",
              "206 CC",
              "206 SW",
              "207",
              "207 CC",
              "207 SW",
              "306",
              "307",
              "307 CC",
              "307 SW",
              "308",
              "308 CC",
              "308 SW",
              "309",
              "4007",
              "4008",
              "405",
              "406",
              "407",
              "407 SW",
              "5008",
              "508",
              "508 SW",
              "605",
              "806",
              "607",
              "807",
              "Bipper",
              "RCZ"
            ]
          },
          {
            "name": "Porsche",
            "models": [
              "911 Carrera",
              "911 Carrera Cabrio",
              "911 Targa",
              "911 Turbo",
              "924",
              "944",
              "997",
              "Boxster",
              "Cayenne",
              "Cayman",
              "Macan",
              "Panamera"
            ]
          },
          {
            "name": "Renault",
            "models": [
              "Captur",
              "Clio",
              "Clio Grandtour",
              "Espace",
              "Express",
              "Fluence",
              "Grand Espace",
              "Grand Modus",
              "Grand Scenic",
              "Kadjar",
              "Kangoo",
              "Kangoo Express",
              "Koleos",
              "Laguna",
              "Laguna Grandtour",
              "Latitude",
              "Mascott",
              "Mégane",
              "Mégane CC",
              "Mégane Combi",
              "Mégane Grandtour",
              "Mégane Coupé",
              "Mégane Scénic",
              "Scénic",
              "Talisman",
              "Talisman Grandtour",
              "Thalia",
              "Twingo",
              "Wind",
              "Zoé"
            ]
          },
          {
            "name": "Rover",
            "models": [
              "200",
              "214",
              "218",
              "25",
              "400",
              "414",
              "416",
              "620",
              "75"
            ]
          },
          {
            "name": "Saab",
            "models": [
              "9-3",
              "9-3 Cabriolet",
              "9-3 Coupé",
              "9-3 SportCombi",
              "9-5",
              "9-5 SportCombi",
              "900",
              "900 C",
              "900 C Turbo",
              "9000"
            ]
          },
          {
            "name": "Seat",
            "models": [
              "Alhambra",
              "Altea",
              "Altea XL",
              "Arosa",
              "Cordoba",
              "Cordoba Vario",
              "Exeo",
              "Ibiza",
              "Ibiza ST",
              "Exeo ST",
              "Leon",
              "Leon ST",
              "Inca",
              "Mii",
              "Toledo"
            ]
          },
          {
            "name": "Smart",
            "models": [
              "Cabrio",
              "City-Coupé",
              "Compact Pulse",
              "Forfour",
              "Fortwo cabrio",
              "Fortwo coupé",
              "Roadster"
            ]
          },
          {
            "name": "Subaru",
            "models": [
              "BRZ",
              "Forester",
              "Impreza",
              "Impreza Wagon",
              "Justy",
              "Legacy",
              "Legacy Wagon",
              "Legacy Outback",
              "Levorg",
              "Outback",
              "SVX",
              "Tribeca",
              "Tribeca B9",
              "XV"
            ]
          },
          {
            "name": "Suzuki",
            "models": [
              "Alto",
              "Baleno",
              "Baleno kombi",
              "Grand Vitara",
              "Grand Vitara XL-7",
              "Ignis",
              "Jimny",
              "Kizashi",
              "Liana",
              "Samurai",
              "Splash",
              "Swift",
              "SX4",
              "SX4 Sedan",
              "Vitara",
              "Wagon R+"
            ]
          },
          {
            "name": "Toyota",
            "models": [
              "4-Runner",
              "Auris",
              "Avensis",
              "Avensis Combi",
              "Avensis Van Verso",
              "Aygo",
              "Camry",
              "Carina",
              "Celica",
              "Corolla",
              "Corolla Combi",
              "Corolla sedan",
              "Corolla Verso",
              "FJ Cruiser",
              "GT86",
              "Hiace",
              "Hiace Van",
              "Highlander",
              "Hilux",
              "Land Cruiser",
              "MR2",
              "Paseo",
              "Picnic",
              "Prius",
              "RAV4",
              "Sequoia",
              "Starlet",
              "Supra",
              "Tundra",
              "Urban Cruiser",
              "Verso",
              "Yaris",
              "Yaris Verso"
            ]
          },
          {
            "name": "Volkswagen",
            "models": [
              "Amarok",
              "Beetle",
              "Bora",
              "Bora Variant",
              "Caddy",
              "Caddy Van",
              "Life",
              "California",
              "Caravelle",
              "CC",
              "Crafter",
              "Crafter Van",
              "Crafter Kombi",
              "CrossTouran",
              "Eos",
              "Fox",
              "Golf",
              "Golf Cabrio",
              "Golf Plus",
              "Golf Sportvan",
              "Golf Variant",
              "Jetta",
              "LT",
              "Lupo",
              "Multivan",
              "New Beetle",
              "New Beetle Cabrio",
              "Passat",
              "Passat Alltrack",
              "Passat CC",
              "Passat Variant",
              "Passat Variant Van",
              "Phaeton",
              "Polo",
              "Polo Van",
              "Polo Variant",
              "Scirocco",
              "Sharan",
              "T4",
              "T4 Caravelle",
              "T4 Multivan",
              "T5",
              "T5 Caravelle",
              "T5 Multivan",
              "T5 Transporter Shuttle",
              "Tiguan",
              "Touareg",
              "Touran"
            ]
          },
          {
            "name": "Volvo",
            "models": [
              "240",
              "340",
              "360",
              "460",
              "850",
              "850 kombi",
              "C30",
              "C70",
              "C70 Cabrio",
              "C70 Coupé",
              "S40",
              "S60",
              "S70",
              "S80",
              "S90",
              "V40",
              "V50",
              "V60",
              "V70",
              "V90",
              "XC60",
              "XC70",
              "XC90"
            ]
          },
          {
            "name": "Škoda",
            "models": [
              "Favorit",
              "Felicia",
              "Citigo",
              "Fabia",
              "Fabia Combi",
              "Fabia Sedan",
              "Felicia Combi",
              "Octavia",
              "Octavia Combi",
              "Roomster",
              "Yeti",
              "Rapid",
              "Rapid Spaceback",
              "Superb",
              "Superb Combi"
            ]
          }
    
      ]
    },
    {
      label: 'Model',
      placeholder: 'Select Model'
    }, {
      label: 'Year',
      placeholder: 'Select Year',
      data: [
        {
          "name": "2024"
        },
        {
          "name": "2023"
        },
        {
          "name": "2022"
        },
        {
          "name": "2021"
        },
        {
          "name": "2020"
        },
        {
          "name": "2019"
        },
        {
          "name": "2018"
        },
        {
          "name": "2017"
        },
        {
          "name": "2016"
        },
        {
          "name": "2015"
        },
        {
          "name": "2014"
        },
        {
          "name": "2013"
        },
        {
          "name": "2012"
        },
        {
          "name": "2011"
        },
        {
          "name": "2010"
        },
        {
          "name": "2009"
        },
        {
          "name": "2008"
        },
        {
          "name": "2007"
        },
        {
          "name": "2006"
        },
        {
          "name": "2005"
        },
        {
          "name": "2004"
        },
        {
          "name": "2003"
        },
        {
          "name": "2002"
        },
        {
          "name": "2001"
        },
        {
          "name": "2000"
        },
        {
          "name": "1999"
        },
        {
          "name": "1998"
        },
        {
          "name": "1997"
        },
        {
          "name": "1996"
        },
        {
          "name": "1995"
        },
        {
          "name": "1994"
        },
        {
          "name": "1993"
        }
      ]
    }, {
      label: 'Color',
      placeholder: 'Select color',
      data: [{
        name: 'Black',
      },
      {
        name: 'Blue',
      },
      {
        name: 'Brown',
      },
      {
        name: 'Gold',
      },
      {
        name: 'Green',
      },
      {
        name: 'Grey',
      },
      {
        name: 'Orange',
      },
      {
        name: 'Red',
      },
      {
        name: 'Tan',
      },
      {
        name: 'White',
      },
  
      ]
    },
    {
      label: 'Car Seat Capacity',
      placeholder: 'Select Car Seat Capacity',
      data: [{
        name: 7,
      },
      {
        name: 6,
      },
      {
        name: 5,
      },
      {
        name: 4,
      },
      {
        name: 3,
      },
      {
        name: 2,
      },
      {
        name: 1,
      }
      ]
    },
  ],
  MARK_OPTIONS: {
    origin: {
      animation: '\'DROP\'',
      label: 'origin',

    },
    destination: {
      animation: '\'DROP\'',
      label: 'destination',

    },
  },
  EDIT_RIDE: [{
    head: 'Journey & stops',
    route: 'journey'
  // }, {
  //   head: 'Passenger contribution',
  //   route: 'edit-price-list'
  }, {
    head: 'Passenger options',
    route: 'passengers-option'
  }],
  NOTIFICATIONS: [{
    title: 'System',
    subtitle: 'Booking #1234 has been Success...',
    iconUrl: 'assets/success.png',
  },
  {
    title: 'Promotion',
    subtitle: 'Inivite friend - Get 3 couponse each',
    iconUrl: 'assets/coupon.png',
  },

  {
    title: 'Promotion',
    subtitle: 'Inivite friend - Get 3 couponse each',
    iconUrl: 'assets/coupon.png',
  },

  {
    title: 'System',
    subtitle: 'Booking #1234 has been Success...',
    iconUrl: 'assets/error.png',
  },

  {
    title: 'System',
    subtitle: 'Booking #1234 has been Success...',
    iconUrl: 'assets/success.png',
  },
  ],
  DOCUMENTS: [{
    name: 'Identification Card',
    icon: 'person',
    url: '/driving-license'
  }, {
    name: 'Driving License',
    icon: 'person',
    url: '/driving-license'
  }],
  FOOTER_CHAT: [{
    icon: 'images',
    label: 'Image'
  }, {
    icon: 'call',
    label: 'Phone'
  }, {
    icon: 'mail-unread',
    label: 'Red'
  }, {
    label: 'Document',
    icon: 'radio-button-on'
  }, {
    icon: 'pin',
    label: 'Position'
  }, {
    icon: 'videocam',
    label: 'Video'
  }
  ],
  PROFILE_DATA: {
    image: '../../assets/image/user4.jpeg',
    name: 'Enappd',
    userType: 'Newcomer',
  },
  PROFILE_DETAILS: [{
    chooseProfile: 'Choose profile photo',
    header: 'About you',
    icon: 'more',
    headerText: 'Write My Mini Biography',
    // headerText1: 'Add My Preferences'
  },
  {
    header: 'Verification',
    icon: 'more',
    // headerText: 'Verify My ID',
    headerText1: 'Verify My Phone Number',
    headerText2: 'Verify My Email',
    verifyId: [{ name: 'passport' }, { name: 'passport' }, { name: 'passport' }],
    verifyNo: [{
      icon: 'phone',
      head: 'Just one thing',
      subheade: 'Please verify your mobile number',
      items: [{ name: 'Region' }, { name: 'Phone number' }]
    }]
  },
  {
    header: 'Car',
    headerText: 'Add a Car',
  },
  {
    header: 'Notifications',
    headerText: 'Notifications Settings',
  }
  ],
  PROFILE: {
    gender: 'Gender',
    displayName: 'Display Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    birthDate: 'Birth Year',
    bio: 'Biography',
    profileButton: 'Save Profile'
  },
  BIOGRAPHY: [{
    headerText: 'About you'
  }, {
    header: 'email',
    label: 'Email',
    model: 'store.herethere.com',
    button: 'Save email'
  }, {
    header: 'Mobile number',
    label: 'Region',
    model: 'India(+91)',
    label1: 'Phone number',
    model1: '123 1234 123',
    button1: 'Save mobile number'
  }],
  PREFERENCE: [{
    icon: 'chatbubbles',
    header: 'Chattiness',
    placeholder: 'I\'m the quiet type',
    list: [{
      model: 'the',
      chattiness: 'I\'m the quiet type'
    },
    {
      model: 'depending',
      chattiness: 'I talk depending on my mood'
    }, {
      model: 'love',
      chattiness: 'I love to chat'
    }]
  }, {
    placeholder: 'No smoking in the car please',
    icon: 'location-outline',
    header: 'Smoking',
    list: [{
      model: 'no',
      chattiness: 'No smoking in the car please'
    },
    {
      model: 'know',
      chattiness: 'Don\'t know'
    }, {
      model: 'ciggrette',
      chattiness: 'Ciggrette smoke doesn\'t bother me'
    }]
  }, {
    placeholder: 'Silence is golden',
    icon: 'musical-notes',
    header: 'music',
    list: [{
      model: 'silence',
      chattiness: 'Silence is golden'
    },
    {
      model: 'do',
      chattiness: 'Don\'t know'
    }, {
      model: 'it',
      chattiness: 'It\'s all about the playlist'
    }]
  }, {
    placeholder: 'No pets please',
    icon: 'paw',
    header: 'pets',
    list: [{
      model: 'pets',
      chattiness: 'No pets please'
    },
    {
      model: 'don',
      chattiness: 'Don\'t know'
    }, {
      model: 'welcome',
      chattiness: 'Pets welcome. Woof!'
    }]
  }],
  COUNTRIES: [
    {
      name: 'Afghanistan',
      dial_code: '+93',
      code: 'AF',
    },
    {
      name: 'Aland Islands',
      dial_code: '+358',
      code: 'AX',
    },
    {
      name: 'Albania',
      dial_code: '+355',
      code: 'AL',
    },
    {
      name: 'Algeria',
      dial_code: '+213',
      code: 'DZ',

    },
    {
      name: 'AmericanSamoa',
      dial_code: '+1 684',
      code: 'AS',
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      code: 'AD',
    },
    {
      name: 'Angola',
      dial_code: '+244',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      dial_code: '+1 264',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      dial_code: '+672',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR'
    },
    {
      name: 'Armenia',
      dial_code: '+374',
      code: 'AM'
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      code: 'AW'
    },
    {
      name: 'Australia',
      dial_code: '+61',
      code: 'AU'
    },
    {
      name: 'Austria',
      dial_code: '+43',
      code: 'AT'
    },
    {
      name: 'Azerbaijan',
      dial_code: '+994',
      code: 'AZ'
    },
    {
      name: 'Bahamas',
      dial_code: '+1 242',
      code: 'BS'
    },
    {
      name: 'Bahrain',
      dial_code: '+973',
      code: 'BH'
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      code: 'BD'
    },
    {
      name: 'Barbados',
      dial_code: '+1 246',
      code: 'BB'
    },
    {
      name: 'Belarus',
      dial_code: '+375',
      code: 'BY'
    },
    {
      name: 'Belgium',
      dial_code: '+32',
      code: 'BE'
    },
    {
      name: 'Belize',
      dial_code: '+501',
      code: 'BZ'
    },
    {
      name: 'Benin',
      dial_code: '+229',
      code: 'BJ'
    },
    {
      name: 'Bermuda',
      dial_code: '+1 441',
      code: 'BM'
    },
    {
      name: 'Bhutan',
      dial_code: '+975',
      code: 'BT'
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO'
    },
    {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA'
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      code: 'BW'
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR'
    },
    {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO'
    },
    {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN'
    },
    {
      name: 'Bulgaria',
      dial_code: '+359',
      code: 'BG'
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF'
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      code: 'BI'
    },
    {
      name: 'Cambodia',
      dial_code: '+855',
      code: 'KH'
    },
    {
      name: 'Cameroon',
      dial_code: '+237',
      code: 'CM'
    },
    {
      name: 'Canada',
      dial_code: ' +1',
      code: 'CA'
    },
    {
      name: 'Cape Verde',
      dial_code: '+238',
      code: 'CV'
    },
    {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY'
    },
    {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF'
    },
    {
      name: 'Chad',
      dial_code: '+235',
      code: 'TD'
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL'
    },
    {
      name: 'China',
      dial_code: '+86',
      code: 'CN'
    },
    {
      name: 'Christmas Island',
      dial_code: '+61',
      code: 'CX'
    },
    {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC'
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO'
    },
    {
      name: 'Comoros',
      dial_code: '+269',
      code: 'KM'
    },
    {
      name: 'Congo',
      dial_code: '+242',
      code: 'CG'
    },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD'
    },
    {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK'
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      code: 'CR'
    },
    {
      name: 'Cote d\'Ivoire',
      dial_code: '+225',
      code: 'CI'
    },
    {
      name: 'Croatia',
      dial_code: '+385',
      code: 'HR'
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      code: 'CU'
    },
    {
      name: 'Cyprus',
      dial_code: '+357',
      code: 'CY'
    },
    {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ'
    },
    {
      name: 'Denmark',
      dial_code: '+45',
      code: 'DK'
    },
    {
      name: 'Djibouti',
      dial_code: '+253',
      code: 'DJ'
    },
    {
      name: 'Dominica',
      dial_code: '+1 767',
      code: 'DM'
    },
    {
      name: 'Dominican Republic',
      dial_code: '+1 849',
      code: 'DO'
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC'
    },
    {
      name: 'Egypt',
      dial_code: '+20',
      code: 'EG'
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      code: 'SV'
    },
    {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ'
    },
    {
      name: 'Eritrea',
      dial_code: '+291',
      code: 'ER'
    },
    {
      name: 'Estonia',
      dial_code: '+372',
      code: 'EE'
    },
    {
      name: 'Ethiopia',
      dial_code: '+251',
      code: 'ET'
    },
    {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK'
    },
    {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO'
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      code: 'FJ'
    },
    {
      name: 'Finland',
      dial_code: '+358',
      code: 'FI'
    },
    {
      name: 'France',
      dial_code: '+33',
      code: 'FR'
    },
    {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF'
    },
    {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF'
    },
    {
      name: 'Gabon',
      dial_code: '+241',
      code: 'GA'
    },
    {
      name: 'Gambia',
      dial_code: '+220',
      code: 'GM'
    },
    {
      name: 'Georgia',
      dial_code: '+995',
      code: 'GE'
    },
    {
      name: 'Germany',
      dial_code: '+49',
      code: 'DE'
    },
    {
      name: 'Ghana',
      dial_code: '+233',
      code: 'GH'
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      code: 'GI'
    },
    {
      name: 'Greece',
      dial_code: '+30',
      code: 'GR'
    },
    {
      name: 'Greenland',
      dial_code: '+299',
      code: 'GL'
    },
    {
      name: 'Grenada',
      dial_code: '+1 473',
      code: 'GD'
    },
    {
      name: 'Guadeloupe',
      dial_code: '+590',
      code: 'GP'
    },
    {
      name: 'Guam',
      dial_code: '+1 671',
      code: 'GU'
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      code: 'GT'
    },
    {
      name: 'Guernsey',
      dial_code: '+44',
      code: 'GG'
    },
    {
      name: 'Guinea',
      dial_code: '+224',
      code: 'GN'
    },
    {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW'
    },
    {
      name: 'Guyana',
      dial_code: '+595',
      code: 'GY'
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      code: 'HT'
    },
    {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA'
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      code: 'HN'
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      code: 'HK'
    },
    {
      name: 'Hungary',
      dial_code: '+36',
      code: 'HU'
    },
    {
      name: 'Iceland',
      dial_code: '+354',
      code: 'IS'
    },
    {
      name: 'India',
      dial_code: '+91',
      code: 'IN'
    },
    {
      name: 'Indonesia',
      dial_code: '+62',
      code: 'ID'
    },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR'
    },
    {
      name: 'Iraq',
      dial_code: '+964',
      code: 'IQ'
    },
    {
      name: 'Ireland',
      dial_code: '+353',
      code: 'IE'
    },
    {
      name: 'Isle of Man',
      dial_code: '+44',
      code: 'IM'
    },
    {
      name: 'Israel',
      dial_code: '+972',
      code: 'IL'
    },
    {
      name: 'Italy',
      dial_code: '+39',
      code: 'IT'
    },
    {
      name: 'Jamaica',
      dial_code: '+1 876',
      code: 'JM'
    },
    {
      name: 'Japan',
      dial_code: '+81',
      code: 'JP'
    },
    {
      name: 'Jersey',
      dial_code: '+44',
      code: 'JE'
    },
    {
      name: 'Jordan',
      dial_code: '+962',
      code: 'JO'
    },
    {
      name: 'Kazakhstan',
      dial_code: '+7 7',
      code: 'KZ'
    },
    {
      name: 'Kenya',
      dial_code: '+254',
      code: 'KE'
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      code: 'KI'
    },
    {
      name: 'Korea, Democratic People\'s Republic of Korea',
      dial_code: '+850',
      code: 'KP'
    },
    {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR'
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      code: 'KW'
    },
    {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      code: 'KG'
    },
    {
      name: 'Laos',
      dial_code: '+856',
      code: 'LA'
    },
    {
      name: 'Latvia',
      dial_code: '+371',
      code: 'LV'
    },
    {
      name: 'Lebanon',
      dial_code: '+961',
      code: 'LB'
    },
    {
      name: 'Lesotho',
      dial_code: '+266',
      code: 'LS'
    },
    {
      name: 'Liberia',
      dial_code: '+231',
      code: 'LR'
    },
    {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY'
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI'
    },
    {
      name: 'Lithuania',
      dial_code: '+370',
      code: 'LT'
    },
    {
      name: 'Luxembourg',
      dial_code: '+352',
      code: 'LU'
    },
    {
      name: 'Macao',
      dial_code: '+853',
      code: 'MO'
    },
    {
      name: 'Macedonia',
      dial_code: '+389',
      code: 'MK'
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      code: 'MG'
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      code: 'MW'
    },
    {
      name: 'Malaysia',
      dial_code: '+60',
      code: 'MY'
    },
    {
      name: 'Maldives',
      dial_code: '+960',
      code: 'MV'
    },
    {
      name: 'Mali',
      dial_code: '+223',
      code: 'ML'
    },
    {
      name: 'Malta',
      dial_code: '+356',
      code: 'MT'
    },
    {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH'
    },
    {
      name: 'Martinique',
      dial_code: '+596',
      code: 'MQ'
    },
    {
      name: 'Mauritania',
      dial_code: '+222',
      code: 'MR'
    },
    {
      name: 'Mauritius',
      dial_code: '+230',
      code: 'MU'
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      code: 'YT'
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX'
    },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM'
    },
    {
      name: 'Moldova',
      dial_code: '+373',
      code: 'MD'
    },
    {
      name: 'Monaco',
      dial_code: '+377',
      code: 'MC'
    },
    {
      name: 'Mongolia',
      dial_code: '+976',
      code: 'MN'
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      code: 'ME'
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS'
    },
    {
      name: 'Morocco',
      dial_code: '+212',
      code: 'MA'
    },
    {
      name: 'Mozambique',
      dial_code: '+258',
      code: 'MZ'
    },
    {
      name: 'Myanmar',
      dial_code: '+95',
      code: 'MM'
    },
    {
      name: 'Namibia',
      dial_code: '+264',
      code: 'NA'
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      code: 'NR'
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      code: 'NP'
    },
    {
      name: 'Netherlands',
      dial_code: '+31',
      code: 'NL'
    },
    {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN'
    },
    {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC'
    },
    {
      name: 'New Zealand',
      dial_code: '+64',
      code: 'NZ'
    },
    {
      name: 'Nicaragua',
      dial_code: '+505',
      code: 'NI'
    },
    {
      name: 'Niger',
      dial_code: '+227',
      code: 'NE'
    },
    {
      name: 'Nigeria',
      dial_code: '+234',
      code: 'NG'
    },
    {
      name: 'Niue',
      dial_code: '+683',
      code: 'NU'
    },
    {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF'
    },
    {
      name: 'Northern Mariana Islands',
      dial_code: '+1 670',
      code: 'MP'
    },
    {
      name: 'Norway',
      dial_code: '+47',
      code: 'NO'
    },
    {
      name: 'Oman',
      dial_code: '+968',
      code: 'OM'
    },
    {
      name: 'Pakistan',
      dial_code: '+92',
      code: 'PK'
    },
    {
      name: 'Palau',
      dial_code: '+680',
      code: 'PW'
    },
    {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS'
    },
    {
      name: 'Panama',
      dial_code: '+507',
      code: 'PA'
    },
    {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG'
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY'
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE'
    },
    {
      name: 'Philippines',
      dial_code: '+63',
      code: 'PH'
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      code: 'PN'
    },
    {
      name: 'Poland',
      dial_code: '+48',
      code: 'PL'
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      code: 'PT'
    },
    {
      name: 'Puerto Rico',
      dial_code: '+1 939',
      code: 'PR'
    },
    {
      name: 'Qatar',
      dial_code: '+974',
      code: 'QA'
    },
    {
      name: 'Romania',
      dial_code: '+40',
      code: 'RO'
    },
    {
      name: 'Russia',
      dial_code: '+7',
      code: 'RU'
    },
    {
      name: 'Rwanda',
      dial_code: '+250',
      code: 'RW'
    },
    {
      name: 'Reunion',
      dial_code: '+262',
      code: 'RE'
    },
    {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      code: 'BL'
    },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH'
    },
    {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1 869',
      code: 'KN'
    },
    {
      name: 'Saint Lucia',
      dial_code: '+1 758',
      code: 'LC'
    },
    {
      name: 'Saint Martin',
      dial_code: '+590',
      code: 'MF'
    },
    {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1 784',
      code: 'VC'
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      code: 'WS'
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      code: 'SM'
    },
    {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST'
    },
    {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA'
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      code: 'SN'
    },
    {
      name: 'Serbia',
      dial_code: '+381',
      code: 'RS'
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      code: 'SC'
    },
    {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL'
    },
    {
      name: 'Singapore',
      dial_code: '+65',
      code: 'SG'
    },
    {
      name: 'Slovakia',
      dial_code: '+421',
      code: 'SK'
    },
    {
      name: 'Slovenia',
      dial_code: '+386',
      code: 'SI'
    },
    {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB'
    },
    {
      name: 'Somalia',
      dial_code: '+252',
      code: 'SO'
    },
    {
      name: 'South Africa',
      dial_code: '+27',
      code: 'ZA'
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS'
    },
    {
      name: 'Spain',
      dial_code: '+34',
      code: 'ES'
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      code: 'LK'
    },
    {
      name: 'Sudan',
      dial_code: '+249',
      code: 'SD'
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      code: 'SR'
    },
    {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ'
    },
    {
      name: 'Swaziland',
      dial_code: '+268',
      code: 'SZ'
    },
    {
      name: 'Sweden',
      dial_code: '+46',
      code: 'SE'
    },
    {
      name: 'Switzerland',
      dial_code: '+41',
      code: 'CH'
    },
    {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY'
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      code: 'TW'
    },
    {
      name: 'Tajikistan',
      dial_code: '+992',
      code: 'TJ'
    },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ'
    },
    {
      name: 'Thailand',
      dial_code: '+66',
      code: 'TH'
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      code: 'TL'
    },
    {
      name: 'Togo',
      dial_code: '+228',
      code: 'TG'
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      code: 'TK'
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      code: 'TO'
    },
    {
      name: 'Trinidad and Tobago',
      dial_code: '+1 868',
      code: 'TT'
    },
    {
      name: 'Tunisia',
      dial_code: '+216',
      code: 'TN'
    },
    {
      name: 'Turkey',
      dial_code: '+90',
      code: 'TR'
    },
    {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM'
    },
    {
      name: 'Turks and Caicos Islands',
      dial_code: '+1 649',
      code: 'TC'
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      code: 'TV'
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      code: 'UG'
    },
    {
      name: 'Ukraine',
      dial_code: '+380',
      code: 'UA'
    },
    {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE'
    },
    {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'GB'
    },
    {
      name: 'United States',
      dial_code: '+1',
      code: 'US'
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY'
    },
    {
      name: 'Uzbekistan',
      dial_code: '+998',
      code: 'UZ'
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      code: 'VU'
    },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE'
    },
    {
      name: 'Vietnam',
      dial_code: '+84',
      code: 'VN'
    },
    {
      name: 'Virgin Islands, British',
      dial_code: '+1 284',
      code: 'VG'
    },
    {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1 340',
      code: 'VI'
    },
    {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF'
    },
    {
      name: 'Yemen',
      dial_code: '+967',
      code: 'YE'
    },
    {
      name: 'Zambia',
      dial_code: '+260',
      code: 'ZM'
    },
    {
      name: 'Zimbabwe',
      dial_code: '+263',
      code: 'ZW'
    }
  ],
  EMAIL_VERIFICATION: [{
    para: 'Control the emails you want to get - all of them, just the important stuff or the bare minimum. You can always unsubscribe from the bottom of email.',
  },
  {
    email: 'Enappd@Enappd.com.',
    toggle: false,
    emailDesc: 'Email not verified. Tap below to request a verification email.'
  }, {
    head: 'New Rides',
    toggle: true

  }, {
    head: 'Ride offerings',
    toggle: true

  }, {
    head: 'Promotions',
    toggle: true,
    des: 'I want to receive news, updates and offers from HereThere.'
  }],
  CARDS: [{
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: '../../assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: '../../assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: '../../assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: '../../assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: 'assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: 'assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: 'assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  },
  {
    iconName: 'caret-down-circle-outline',
    iconName2: 'location-outline',
    label: 'Near Delhi To Ajmer Bypass',
    image: 'assets/image/user4.jpeg',
    label2: 'Benar Road, Dadi Ka Phatak,',
    text: '10/13/17 at 20:26',
    text2: '325 Rs.',
    head: 'Maruti Suzuki alto(Sedan)',
  }
  ],
  TAB_ROUTING: [{
    tab: 'home',
    iconName: 'calendar',
    label: 'Rides',

  }, {
    tab: 'find-ride',
    iconName: 'search',
    label: 'Search',

  }, {
    tab: 'offer',
    iconName: 'add-circle',
    label: 'Offer',

  }, 
  // {
  //   tab: 'inbox',
  //   iconName: 'chatbubbles',
  //   label: 'Inbox',

  // }, 
  {
    tab: 'profile',
    iconName: 'person',
    label: 'Profile',
  }],
  RIDES_LIST: [{
    image: 'assets/image/images.jpg',
    name: 'Allen Chanadenar',
    car: 'Toyota prius 007,G5 5867, Mini white',
    numberPlate: '5867 Traffic way',
    cost: '500'
  }, {
    image: 'assets/image/chat2.jpg',
    name: 'Julia',
    car: 'Toyota prius 007,G5 7855, Mini white',
    numberPlate: '7855 Traffic way',
    cost: '480'

  }, {
    image: 'assets/image/chat6.jpg',
    name: 'Barbie',
    car: 'Toyota prius 007,G5 7876, Mini white',
    numberPlate: '7876 Traffic way',
    cost: '800'

  }, {
    image: 'assets/image/chat7.jpg',
    name: 'Cristina',
    car: 'Toyota prius 007,G5 7856, Mini white',
    numberPlate: '7856 Traffic way',
    cost: '640'

  }]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
