import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs/operators';
import { Profile, ProfileClass } from '@app/pages/models/profile';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { UtilService } from '@app/pages/services/util.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userData;
  profile: Profile;
  user;

  constructor(
    private firestore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    private store: Store<fromPages.State>,
    private utilService: UtilService,
  ) {

    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        this.user = { ...value };
      });

    this.store.pipe(select(fromPages.selectProfileData))
      .subscribe(value => {
        this.profile = { ...value };
      });
  }

  getProfile(): Observable<any> {
    return this.firestore.collection('profile', ref =>
      ref.where("userId", "==", this.user.uid)
    )
      .valueChanges({ idField: 'uid' });
  }


  getProfileAll(): Observable<any> {
    let profiles: any[] = [];

    return this.firestore.collection('profile').get()
      .pipe(map((doc) => {
        doc.forEach((item) => {
          let obj = item.data();
          let newObj = this.utilService.shallowCopyObject(obj, ProfileClass)
          profiles.push(newObj);
        });
        return profiles;
      }));
  }

  upsertProfile(data): Observable<void> {
    const newProfile = new ProfileClass();
    const keys = Object.keys(newProfile);

    keys.forEach((prop: any) => {
      if (this.profile) {
        newProfile[prop] = this.profile[prop] ? this.profile[prop] : newProfile[prop];
      }

      newProfile[prop] = data[prop] ? data[prop] : newProfile[prop];
    })

    if (newProfile.userId == '') {
      newProfile.userId = this.user.uid;
    }
    
    let profile: Profile = { ...newProfile };

    const doc = this.firestore.collection('profile').doc(profile.uid)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()

    return from(doc.then((snap): any => {
      return snap.payload.exists ? this.firestore.collection('profile').doc(profile.uid).update(profile) : this.firestore.collection('profile').add(profile);
    }));
  }

  deleteProfile(profile: Profile) {
    this.firestore.collection('profile').doc(this.profile.uid).delete();
  }
}