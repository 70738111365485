import { EditProfilePageActions } from '@app/pages/actions';
import { createReducer, on } from '@ngrx/store';
import { Profile } from '@app/pages/models';

export const editProfilePageFeatureKey = 'editProfilePage';

export interface State {
  profiles: any[];
  profile: any;
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  profiles: [],
  profile: {} as Profile,
  error: null,
  pending: false,
};

export const reducer = createReducer(
  initialState,

  on(EditProfilePageActions.profileUpsertSuccess, (state) => ({
    ...state,
    error: null,
    pending: false,
  })),

  on(EditProfilePageActions.profileUpsertFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(EditProfilePageActions.profileGet, (state) => ({
    ...state,
    pending: true,
  })),

  on(EditProfilePageActions.profileGetSuccess, (state, { profile }) => {
    let profileData = {} as Profile;
    if (profile) {
      profileData = { ...profile[0] }
    }

    return ({
      ...state,
      profile: profileData,
      error: null,
      pending: false,
    })
  }
  ),

  on(EditProfilePageActions.profileGetFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(EditProfilePageActions.profileGetAllSuccess, (state, { profiles }) => ({
    ...state,
    profiles: profiles,
    error: null,
    pending: false,
  })),

  on(EditProfilePageActions.profileGetAllFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  }))
  
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getProfile = (state: State) => state.profile;
export const getProfileAll = (state: State) => state.profiles;


