import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoginService } from '@app/pages/services/login.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserPageActions } from '@app/pages/actions';
import { UserService } from '@app/pages/services/user.service';
import * as fromPages from '@app/pages/reducers';


@Injectable()
export class UserEffects {
    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPageActions.userUpdate),
            map(action => action.data),
            exhaustMap((data: any) =>
                this.userService.updateUser(data).pipe(
                    map((user) => UserPageActions.userUpdateSuccess({ user })),
                    catchError(error => of(UserPageActions.userUpdateFailure({ error })))
                )
            )
        )
    );

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPageActions.userGet),
            map(action => action),
            exhaustMap(() =>
                this.userService.getUser().pipe(
                    map((user: any) => UserPageActions.userGetSuccess({ user })),
                    catchError(error => of(UserPageActions.userGetFailure({ error })))
                )
            )
        )
    );

    getUserAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPageActions.userGetAll),
            map(action => action),
            exhaustMap(() =>
                this.userService.getUserAll().pipe(
                    map((users: any) => UserPageActions.userGetAllSuccess({ users })),
                    catchError(error => of(UserPageActions.userGetAllFailure({ error })))
                )
            )
        )
    );

    updateNotificationSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserPageActions.updateNotificationSettings),
            map(action => action.user),
            exhaustMap((user) =>
                this.userService.updateUser(user).pipe(
                    map((user) => UserPageActions.updateNotificationSettingsSuccess({ user })),
                    catchError(error => of(UserPageActions.updateNotificationSettingsFailure({ error })))
                )
            )
        )
    );


    constructor(
        private actions$: Actions,
        private userService: UserService,
    ) { }
}
