export interface Credentials {
  username: string;
  password: string;
}

export class UserClass {
  uid?: string 
  displayName?: string; 
  photoURL?: string; 
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  region?: string;
  settings?: any;

  constructor() {
    this.uid = '';
    this.displayName = '';
    this.photoURL = '';
    this.email = '';
    this.emailVerified = false;
    this.phoneNumber = '';
    this.phoneNumberVerified = false;
    this.region = '';
    this.settings = { email: false, sms: false, push: false};
  }
}

export interface User extends UserClass {}

export type UserPropsArray = Array<keyof User>