import { UserPageActions } from '@app/pages/actions';
import { createReducer, on } from '@ngrx/store';
import { User } from '@app/pages/models';

export const userPageFeatureKey = 'userPage';

export interface State {
    user: any;
    users: any[];
    error: string | null;
    pending: boolean;
    settings: any;
}

export const initialState: State = {
    user: null,
    users: null,
    error: null,
    pending: false,
    settings: { email: false, sms: false, push: false},
};

export const reducer = createReducer(
    initialState,
    on(UserPageActions.userUpsert, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(UserPageActions.userUpsertSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        pending: false,
    })),

    on(UserPageActions.userUpsertFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(UserPageActions.userUpdate, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(UserPageActions.userUpdateSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        pending: false,
    })),

    on(UserPageActions.userUpdateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(UserPageActions.userGetSuccess, (state, { user }) => {
        if (!user) {
            return { ...state };
        }

        return {
            ...state,
            user: user,
            error: null,
            pending: false
        };
    }),

    on(UserPageActions.userGetFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(UserPageActions.userGetAllSuccess, (state, { users }) => ({
        ...state,
        users: users,
        error: null,
        pending: false,
    })),

    on(UserPageActions.userGetAllFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(UserPageActions.userSignUp, (state, { data }) => ({
        ...state,
        user: { email: data.email, emailVerified: false },
        pending: false,
    })),

    on(UserPageActions.updateNotificationSettings, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(UserPageActions.updateNotificationSettingsSuccess, (state, { user }) => ({
        ...state,
        settings: user.settings,
    })),

    on(UserPageActions.updateNotificationSettingsFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getUser = (state: State) => state.user;
export const getAllUsers = (state: State) => state.users;
export const getSettings = (state: State) => state.settings;



// import { createAction, props, createReducer, on, createSelector, createFeatureSelector } from '@ngrx/store';
// import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

// // Define the state
// export interface State extends EntityState<User> {
//   selectedUserId: string | null;
//   error: string | null;
//   pending: boolean;
//   settings: any;
// }

// // Create the entity adapter
// export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

// // Define the initial state
// export const initialState: State = adapter.getInitialState({
//   selectedUserId: null,
//   error: null,
//   pending: false,
//   settings: { email: false, sms: false, push: false },
// });

// // Define the reducer
// export const reducer = createReducer(
//   initialState,
//   // Use the adapter to handle adding/updating/removing users
//   on(UserPageActions.userUpsertSuccess, (state, { user }) => adapter.upsertOne(user, state)),
//   on(UserPageActions.userUpdateSuccess, (state, { user }) => adapter.updateOne({ id: user.id, changes: user }, state)),
//   // ...
// );

// // Define selectors
// export const selectUserState = createFeatureSelector<State>('user');
// export const { selectAll, selectEntities } = adapter.getSelectors(selectUserState);
// export const selectSelectedUser = createSelector(
//   selectEntities,
//   selectUserState,
//   (entities, state) => entities[state.selectedUserId]
// );