import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { CalendarActions } from '@app/pages/actions';
import { Event } from '@app/pages/models';
import { CalendarService } from '@app/pages/services/calendar.service';


@Injectable()
export class CalendarEffects {

    eventCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventCreate),
            map(action => action.event),
            exhaustMap((event: any) =>
                this.calendarService.eventCreate(event).pipe(
                    map(() => CalendarActions.eventCreateSuccess()),
                    catchError((error) => of(CalendarActions.eventCreateFailure({ error })))
                )
            )
        )
    );

    getCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventGet),
            map(action => action.id),
            exhaustMap((id) =>
                this.calendarService.getEvent(id).pipe(
                    map((event: any) => CalendarActions.eventGetSuccess({ event })),
                    catchError((error) => of(CalendarActions.eventGetFailure({ error })))
                )
            )
        )
    );

    getCalendarAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventGetAll),
            map(action => action),
            exhaustMap(() =>
                this.calendarService.getEventAll().pipe(
                    map((events: any[]) => CalendarActions.eventGetAllSuccess({ events })),
                    catchError((error) => of(CalendarActions.eventGetAllFailure({ error })))
                )
            )
        )
    );

    // eventSuccess$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(CalendarActions.eventUpdateSuccess),
    //             // tap(() => this.router.navigate(['/tabs/event']))
    //         ),
    //     { dispatch: false }
    // );

    deleteCalendar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.eventDelete),
            map(action => action.id),
            exhaustMap((id) =>
                this.calendarService.deleteEvent(id).pipe(
                    map(() => CalendarActions.eventDeleteSuccess()),
                    catchError((error) => of(CalendarActions.eventDeleteFailure({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private calendarService: CalendarService,
        private router: Router,
    ) { }
}
