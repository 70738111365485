
<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-button slot="icon-only" (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Select Stopover</ion-title>
  </ion-toolbar>
</ion-header>


<ion-content class="ion-padding">
  <ion-item>
    <h1>Stop-overs</h1>
  </ion-item>
  <ion-searchbar placeholder="e.g Atlanta, Georgia" class="background-transparent" [(ngModel)]="searchItem"
    showCancelButton="always" debounce="500" (ionFocus)="onFocus()" (ionInput)="searchOnChange()"
    (ionCancel)="onDismiss()" (ionBlur)="onBlur()" selected="true" autofocus="true">
  </ion-searchbar>

  <ion-card *ngIf="searchItem !== '' && showSearchResults">
    <ion-list style="box-shadow: 0 0px 6px #bdbdbd!important;">
      <ion-item *ngFor="let item of autocompleteItems" class="item-request-ride" button="true"
        (click)="chooseItem(item)">
        <ion-icon name="location-outline" slot="start" color="secondary"></ion-icon>
        <ion-label>
          <h2>{{item}}</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
  <ion-item class="item-request-ride" button="true" (click)="getCurrentLocation()">
    <ion-icon *ngIf="!usingGPS" name="location-outline" slot="start" color="secondary"></ion-icon>
    <ion-icon *ngIf="usingGPS" name="location" slot="start" color="secondary"></ion-icon>
    <ion-label>
      <h2>{{usingGPS?'Using':'Use'}} current location</h2>
    </ion-label>
  </ion-item>

  <google-map *ngIf="showMap" [center]="location.center" [zoom]="zoom"
    fullscreenControl="true">
    <map-marker latitude="location.latitude" longitude="location.longitude" markerDraggable="true"
      animation="'DROP'">
      <map-info-window>
        <strong>Info Window content</strong>
      </map-info-window>
    </map-marker>
  </google-map>
  <div class="map-gap">
  </div>
  <ion-grid *ngIf="address && address.value">
    <ion-row>
      <ion-col>
        <ion-text class="label-small">Stop Over Address</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="10">
        <ion-text class="lighter">{{address.value}}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
<ion-footer class="ion-padding">
  <ion-toolbar>
    <ion-button expand="block" color="secondary" type="submit" (click)="addStopOver()">Add Stop-over</ion-button>
  </ion-toolbar>
</ion-footer>