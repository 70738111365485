
import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.page.html',
  styleUrls: ['./calendar.page.scss'],
})
export class CalendarPage implements OnInit {
  public dateArraymyDate;
  showFabButton = false;
  onward: any;
  public myDate;

  constructor(
    public dataService: DataService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    public modalCtrl: ModalController,

  ) {
    if (this.dataService.rideType === 'offer') {
      this.onward = this.dataService.rideDirection === 'onward';
    }
    // this.activeRoute.params.subscribe(params => {
    //   this.tripValue = params.value;
    // });
    let dateArray = new Date().toISOString().split(':');
    this.myDate= moment().format();
  }

  onChange() {
    this.showFabButton = true;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  goToDrop(myDate) {
    const dateOptions: any = { timeStyle: 'short' };
    if (this.dataService.rideType === 'offer') {
      if (this.dataService.rideDirection === 'onward') {
        this.dataService.onwardRideDetails.startDate = new Date(myDate).toDateString();
        this.dataService.onwardRideDetails.startTime = new Date(myDate).toLocaleTimeString([], dateOptions);
        this.route.navigate(['total-passengers']);
      } else if (this.dataService.rideDirection === 'return') {
        this.dataService.returnRideDetails.startDate = new Date(myDate).toDateString();
        this.dataService.returnRideDetails.startTime = new Date(myDate).toLocaleTimeString([], dateOptions);
        this.route.navigate(['total-passengers']);
      }
    } else {
      this.dataService.findRideDetails.startDate = new Date(myDate).toDateString();
      this.dataService.findRideDetails.startTime = new Date(myDate).toLocaleTimeString([], dateOptions);
      this.closeModal();
    }
  }

  ngOnInit() {
  }


}
