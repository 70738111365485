export class ProfileClass {
    bio: string;
    birthDate: string;
    currentUserCar: any;
    firstName: string;
    gender: string;
    userId?: string;
    uid: string = '';
    lastName: string;
    displayName?: string;
    selectedCar: string;
    userPhone: string;
    userEmail: string;

    constructor() {
        this.bio = '';
        this.birthDate = '';
        this.firstName = '';
        this.currentUserCar = {};
        this.gender = '';
        this.userId = '';
        this.uid = ' ';
        this.lastName = '';
        this.displayName = '';
        this.selectedCar = '';
        this.userPhone = '';
        this.userEmail = '';
    }
};


export interface Profile extends ProfileClass { }
export type ProfilePropsArray = Array<keyof Profile>
