
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapDirectionComponent } from '@app/components/map-direction/map-direction.component';
import { ChatListComponent } from '@app/components/chat-list/chat-list.component';
import { MessagingComponent } from '@app/components/messaging/messaging.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChattingComponent } from '@app/components/chatting/chatting.component';
// import { StopOverLocationComponent } from '@app/components/stop-over-location/stop-over-location.component';

@NgModule({
  declarations: [MapDirectionComponent, ChatListComponent, ChattingComponent, MessagingComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    FormsModule,
    GoogleMapsModule,
  ],
  exports: [MapDirectionComponent, ChatListComponent, ChattingComponent, MessagingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShareableModule { }
