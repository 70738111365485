import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { Geolocation } from '@capacitor/geolocation';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy';
import { UtilService } from '@app/pages/services/util.service';
import { NotificationService } from '@app/pages/services/notification.service';
import { environment } from '@env/environment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { UserService } from '@app/pages/services/user.service';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';

declare var google;


@Injectable({
  providedIn: 'root'
})
export class LocationService {
  location: { center: google.maps.LatLngLiteral, accuracy: number, timestamp: number };
  currentAddress = { value: '' };
  // pickupLocation = { latitude: 12.9572115, longitude: 80.226108, accuracy: 0, timestamp: 0 };
  // pickupAddress = { value: 'TN Chennai Pallikaranai Marshland Perungudi Corporation Road' };;
  // dropLocation = { latitude: 8.4834201, longitude: 76.9198194, accuracy: 0, timestamp: 0 };
  // dropAddress = { value: 'Trivandrum International Airport (TRV), Airport Road, Chacka, Thiruvananthapuram' };
  pickupLocation: { center: google.maps.LatLngLiteral, accuracy: number, timestamp: number };
  pickupAddress = { value: '' };
  dropLocation: { center: google.maps.LatLngLiteral, accuracy: number, timestamp: number };
  dropAddress = { value: '' };
  locationTime: any;
  locatedCountry = environment.country;
  canUseGPS = false;
  private user;
  isTracking = false;
  watch: any;
  locations: Observable<any>;
  locationsCollection: AngularFirestoreCollection<any>;
  currentPosition: any = { id: ' ' };
  currentRide: any = {};
  private loader;
  private myPosition: any;
  private myRidePosition: any;
  private NEAR_DISTANCE = 2;

  constructor(
    private platform: Platform,
    public util: UtilService,
    public zone: NgZone,
    private firestore: AngularFirestore,
    private store: Store<fromPages.State>,
    private notificationService: NotificationService,
  ) {
    this.location = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.myPosition = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.myRidePosition = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.pickupLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.dropLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.locationTime = Date.now();
    if (!this.platform.is('cordova')) {
      this.canUseGPS = true;
    }

    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        if (value) {
          this.user = value;
        }
      });

    this.loader = new Loader({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    this.getMyPosition()
  }

  resetCurrentLocation() {
    this.location.center.lat = 0;
    this.location.center.lng = 0;
    this.currentAddress.value = '';
  }
  resetAddresses() {
    this.location = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.currentAddress.value = '';
    this.pickupLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.pickupAddress.value = '';
    this.dropLocation = {
      center: {
        lat: 0,
        lng: 0,
      },
      accuracy: 0,
      timestamp: 0
    };
    this.dropAddress.value = '';
  }

  // Check if application having GPS access permission
  checkGPSPermission() {
    if (this.platform.is('cordova')) {
      AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(
        result => {
          if (result.hasPermission) {
            // If having permission show 'Turn On GPS' dialogue
            this.askToTurnOnGPS();
          } else {

            // If not having permission ask for permission
            this.requestGPSPermission();
          }
        },
        err => {
          alert(err);
        }
      );
    }

  }

  requestGPSPermission() {
    LocationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
      } else {
        // Show 'GPS Permission Request' dialogue
        AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
          .then(
            (result) => {
              if (result.hasPermission) {
                // call method to turn on GPS
                this.askToTurnOnGPS();
              } else {
                alert('Error requesting location permissions ');
              }
            },
            error => {
              // Show alert if user click on 'No Thanks'
              alert('requestPermission Error requesting location permissions ' + error);
            }
          );
      }
    });
  }

  askToTurnOnGPS() {
    LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      (result) => {
        // When GPS Turned ON call method to get Accurate location coordinates
        // Following is the default method called after permission and turning on GPS
        // You can call any default method here
        this.canUseGPS = true;
      },
      error => alert('Error requesting location permissions ' + JSON.stringify(error))
    );
  }

  // Method to get device accurate coordinates using device GPS
  getCurrentLocationCoordinates() {
    Geolocation.getCurrentPosition().then((resp) => {
      this.location.center.lat = resp.coords.latitude;
      this.location.center.lng = resp.coords.longitude;
      this.location.accuracy = resp.coords.accuracy;
      this.location.timestamp = resp.timestamp;
    }).catch((error) => {
      alert('Error getting location' + error);
    });
  }

  getMyPosition() {
    Geolocation.getCurrentPosition().then((resp) => {
      this.myPosition.center.lat = resp.coords.latitude;
      this.myPosition.center.lng = resp.coords.longitude;
      this.myPosition.accuracy = resp.coords.accuracy;
      this.myPosition.timestamp = resp.timestamp;
    }).catch((error) => {
      alert('Error getting myPosition' + error);
    });
  }


  // Method to get device accurate address using device Lat/Lng
  async getGeoCodedAddress(lat: number, lng: number) {
    let block, street, building, country;

    if (navigator.geolocation) {
      const geocoder = await new google.maps.Geocoder();
      const latlng = await new google.maps.LatLng(lat, lng);
      const request = { latLng: latlng };

      await new Promise((resolve, reject) => {

        geocoder.geocode(request, (results, status) => {
          let localAdd1 = '';
          let localAdd2 = '';
          if (status === google.maps.GeocoderStatus.OK) {
            const result = results[0];
            const rsltAdrComponent = result.address_components;
            if (result !== null) {
              if (rsltAdrComponent[0] !== null) {
                block = rsltAdrComponent[0].long_name;
                street = rsltAdrComponent[2].short_name;
                building = rsltAdrComponent[1].short_name;
              }
              // Find out country of geolocation
              // eslint-disable-next-line @typescript-eslint/no-unused-vars

              for (const item of rsltAdrComponent) {
                if (item.types && item.types.includes('country')) {
                  country = item.short_name;
                }
                if (item.types && item.types.includes('administrative_area_level_1')) {
                  localAdd1 = item.short_name;
                }
                if (item.types && item.types.includes('locality')) {
                  localAdd2 = item.short_name;
                }
              }
              // this.userProvider.getUserData().location = localAdd1 + ', ' + localAdd2;
              resolve(true);

            } else {
              alert('No address available!');
            }
          }
          this.currentAddress.value = (localAdd1 + ' ' + localAdd2 + ' ' + building + ' ' + street + ' ' + block).trim();
        });
      });
    }
    return { block, street, building, country };

  }

  // Method to get device's accurate coordinate and current address
  // It is a combination of above two methods
  async getCurrentLocationAndAddress() {
    const loader = await this.util.createLoader('Getting your location...');
    return await new Promise((resolve, reject) => {
      loader.present();
      Geolocation.getCurrentPosition().then((resp: { coords: { latitude: number; longitude: number; }; }) => {
        this.zone.run(async () => {
          this.location.center.lat = resp.coords.latitude;
          this.location.center.lng = resp.coords.longitude;
          await this.getGeoCodedAddress(resp.coords.latitude, resp.coords.longitude);
          loader.dismiss();
          resolve(true);
        });
        loader.dismiss();
      }).catch((error) => {
        console.log(error);
        loader.dismiss();
      }).finally(() => {
        loader.dismiss();
      });
    });
  }


  // Method to get device accurate Lat/Lng using device Address
  async getLatLan(address: string) {
    const geocoder = new google.maps.Geocoder();
    // tslint:disable-next-line: max-line-length
    return await new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results: { geometry: { location: any; }; }[], status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.location.center.lat = results[0].geometry.location.lat();
          this.location.center.lng = results[0].geometry.location.lng();
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async getAutoCompleteResults(searchTerm) {
    return await new Promise((resolve, reject) => {
      // this.loader.load().then(() => {
      // tslint:disable-next-line:no-string-literal
      const service = new window['google'].maps.places.AutocompleteService();
      service.getPlacePredictions({ input: searchTerm, componentRestrictions: { country: this.locatedCountry } }, (predictions, status) => {
        const autocompleteItems = [];
        this.zone.run(() => {
          if (predictions !== null) {
            predictions.forEach((prediction) => {
              autocompleteItems.push(prediction.description);
            });
          }
          resolve(autocompleteItems);
        });
      });
    });
    // });
  }

  async getCurrentLocationAndAddressForType(type) {
    return await new Promise(async (resolve, reject) => {
      await this.getCurrentLocationAndAddress();
      if (type === 'pickup') {
        this.pickupLocation.center.lat = this.location.center.lat;
        this.pickupLocation.center.lng = this.location.center.lng;
        this.pickupLocation.accuracy = this.location.accuracy;
        this.pickupLocation.timestamp = this.location.timestamp;
        this.pickupAddress.value = this.currentAddress.value;
      } else if (type === 'drop') {
        this.dropLocation.center.lat = this.location.center.lat;
        this.dropLocation.center.lng = this.location.center.lng;
        this.dropLocation.accuracy = this.location.accuracy;
        this.dropLocation.timestamp = this.location.timestamp;
        this.dropAddress.value = this.currentAddress.value;
      }
      resolve(true);
    });
  }

  async getGeoCodedAddressForType(lat: number, lng: number, type) {
    return await new Promise(async (resolve, reject) => {
      await this.getGeoCodedAddress(lat, lng);
      if (type === 'pickup') {
        this.pickupAddress.value = this.currentAddress.value;
      } else if (type === 'drop') {
        this.dropAddress.value = this.currentAddress.value;
      }
      resolve(true);
    });
  }

  getCurrentLocationCoordinatesForType(type) {
    Geolocation.getCurrentPosition().then((resp) => {
      if (type === 'pickup') {
        this.pickupLocation.center.lat = resp.coords.latitude;
        this.pickupLocation.center.lng = resp.coords.longitude;
        this.pickupLocation.accuracy = resp.coords.accuracy;
        this.pickupLocation.timestamp = resp.timestamp;
      }
      if (type === 'drop') {
        this.dropLocation.center.lat = resp.coords.latitude;
        this.dropLocation.center.lng = resp.coords.longitude;
        this.dropLocation.accuracy = resp.coords.accuracy;
        this.dropLocation.timestamp = resp.timestamp;
      }
    }).catch((error) => {
      alert('Error getting location' + error);
    });
  }

  async getLatLanForType(address: string, type) {
    return await new Promise(async (resolve, reject) => {
      await this.getLatLan(address);
      if (type === 'pickup') {
        this.pickupLocation.center.lat = this.location.center.lat;
        this.pickupLocation.center.lng = this.location.center.lng;

      }
      if (type === 'drop') {
        this.dropLocation.center.lat = this.location.center.lat;
        this.dropLocation.center.lng = this.location.center.lng;
      }
      resolve(true);
    }).catch((error) => {
      alert('Error getting location' + error);
    });
  }





  // Geolocation tracking /////////////////////////////

  // watchUserLocation() {
  //   let locationRef = this.firestore.collection(
  //     `locations/${this.user.uid}/track`,
  //     ref => ref.orderBy('timestamp')
  //   );

  //   return locationRef.snapshotChanges().pipe(
  //     map(actions =>
  //       actions.map((a: any) => {
  //         const data = a.payload.doc.data();
  //         const id = a.payload.doc.id;
  //         return { id, ...data };
  //       })
  //     )
  //   );
  // }

  getLocation(ride) {
    this.currentRide = ride;
    this.locationsCollection = this.firestore.collection(
      `locations/${this.currentRide.uid}/track`,
      ref => ref.orderBy('timestamp')
    );

    // Make sure we also get the Firebase item ID!
    return this.locationsCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map((a: any) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.currentPosition = { id, ...data };
          return this.currentPosition;
        })
      )
    );
  }

  // Use Capacitor to track our geolocation
  async startTracking() {
    this.isTracking = true;
    if (this.currentRide.offeredByUser == this.user.uid) {
      this.watch = await Geolocation.watchPosition({}, (position, err) => {
        if (position && this.isTracking) {
          this.upsertLocation(
            position.coords.latitude,
            position.coords.longitude,
            position.timestamp
          );
          this.myRidePosition = {
            center: {
              lat: position.coords.latitude, lng: position.coords.longitude
            },
            timestamp: position.timestamp,
          }
          this.isRideApproaching(this.myRidePosition);
        } else {
          console.log('not tracking');
        }
      })
        .catch((error) => {
          console.log('Error getting location for location marker', error);
        });
    }
  }

  // Unsubscribe from the geolocation watch using the initial ID
  stopTracking() {
    Geolocation.clearWatch({ id: this.watch }).then(() => {
      this.isTracking = false;
    });
  }

  // Save a new location to Firebase and center the map
  upsertLocation(lat, lng, timestamp) {
    let doc = this.locationsCollection.doc(this.currentPosition.id)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise()

    return doc.then((snap: any): any => {
      return snap.payload.exists ?
        this.firestore.collection(`locations/${this.currentRide.uid}/track`).doc(this.currentPosition.id).update({ lat, lng, timestamp }) :
        this.firestore.collection(`locations/${this.currentRide.uid}/track`).add({ lat, lng, timestamp });
    });

    // let position = new google.maps.LatLng(lat, lng);
    // this.map.setCenter(position);
    // this.map.setZoom(5);
  }

  // Delete a location from Firebase
  deleteLocation(pos) {
    this.locationsCollection.doc(pos.id).delete();
  }

  isRideApproaching(currentLocation) {
    if (!this.currentRide.status) {
      if (this.isNear(currentLocation, this.currentRide.pickupLocation)) {
        this.currentRide.direction = 'onward';
        this.currentRide.status = 'started-onward';
      } else if (this.isNear(currentLocation, this.currentRide.dropLocation)) {
        this.currentRide.direction = 'return';
        this.currentRide.status = 'started-return';
      }
      this.currentRide.nearLocation = { address: this.currentRide.pickupAddress }; // Set the first location
      this.sendRideNotification(this.currentRide);
      return;
    }

    // Assuming 'stopovers' is an array of locations in the ride.stopovers
    if (this.currentRide.stopovers.length != 0) {
      for (let i = 0; i < this.currentRide.stopovers.length; i++) {
        let location = { center: { lat: this.currentRide.stopovers[i].latitude, lng: this.currentRide.stopovers[i].longitude } };

        // Check if the ride is approaching the location
        if (this.isNear(currentLocation, location)) {
          // If the ride's status is already 'approaching' and the near location is the same, return
          if (this.currentRide.status === 'approaching' && this.currentRide.nearLocation === this.currentRide.stopovers[i]) {
            return;
          }

          this.currentRide.status = 'approaching';
          this.currentRide.nearLocation = this.currentRide.stopovers[i]; // Set the next location

          // Send a notification
          this.sendRideNotification(this.currentRide);
          break;
        }
      }
    }

    // If the ride is near the destination, set the status to 'arrived'
    if (this.currentRide.direction === 'onward' && this.isNear(currentLocation, this.currentRide.dropLocation)) {
      if (this.currentRide.status === 'arrived-onward') {
        return;
      }

      this.currentRide.status = 'arrived-onward';
      this.currentRide.nearLocation = { address: this.currentRide.dropLocation };
      this.stopTracking();

      // Send a notification
      this.sendRideNotification(this.currentRide);

    } else if (this.currentRide.direction === 'return' && this.isNear(currentLocation, this.currentRide.pickupLocation)) {

        if (this.currentRide.status === 'arrived-return') {
          return;
        }

        this.currentRide.status = 'arrived-return';
        this.currentRide.nearLocation = { address: this.currentRide.dropLocation };
        this.stopTracking();

        // Send a notification
        this.sendRideNotification(this.currentRide);
    }
  }
  // Check if two locations are near each other
  isNear(location1, location2) {
    let distance = this.distance(location1, location2);
    return distance < this.NEAR_DISTANCE; // Assuming NEAR_DISTANCE is the distance to consider two locations near each other
  }

  /**
* Calculates the distance, in kilometers, between two locations, via the
* Haversine formula. Note that this is approximate due to the fact that
* the Earth's radius varies between 6356.752 km and 6378.137 km.
*
* @param {Object} location1 The first location given as .latitude and .longitude
* @param {Object} location2 The second location given as .latitude and .longitude
* @return {number} The distance, in kilometers, between the inputted locations.
*/
  distance(location1, location2) {

    function degreesToRadians(degrees) { return (degrees * Math.PI) / 180; }
    const radius = 6371; // Earth's radius in kilometers
    const latDelta = degreesToRadians(location2.center.lat - location1.center.lat);
    const lonDelta = degreesToRadians(location2.center.lng - location1.center.lng);

    const a = (Math.sin(latDelta / 2) * Math.sin(latDelta / 2)) +
      (Math.cos(degreesToRadians(location1.center.lat)) * Math.cos(degreesToRadians(location2.center.lat)) *
        Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return radius * c;
  }

  sendRideNotification(ride) {
    let message = {
      tokens: [
        "fRqEQE_4HuPfcbb3ZeHJGj:APA91bHp1vqOqDc24c7jJKzesR_GYp0TQpxDQDuH84DUcGSBEyWr_i5ydKTo2oVx5L3fcUrQ2wqpf3Hs4NETj1Jjjm-p8UUvKa1MGy-S-pw89vBG44ANq8TS70ttJSId2P-0Laegvfrn", // gmail account
        "e4jntdlnvf6uedhyurjwni:APA91bGt6pGZib6XYLr11LlABKw3AEQ5WgYdrUKK4C_QdQWHmGcUP_qJySncnwQ9I-x6brhk8Wld1XGT2zOjENu5NxRlvx1LJf3SB3OI7sSxKxF0yRjQTOv9926utZY1HZXJb7c6S9xi",
        "dbsnjlpphkreu3vc6h-w2p:APA91bFgqfQTZA2qwFWS2PXXGRe2Dn7LAgngc7uvqMv2mS6j1R4OTllqE5BNLFrfKPb3skvcojCzhT_2LS0fka3wgQj2woDGbCOALJUEBFvV4Pash5geubZf1aD1IDfcqk61wKh4T7Zd"
      ],
      title: '',
      content: '',
      data: { type: '' }
    }

    switch (ride.status) {
      case 'started-onward':
        message.title = 'Ride Started';
        message.content = `Your ride has started from ${ride.pickupAddress}`;
        message.data.type = 'ride-started';
        break;
      case 'started-return':
        message.title = 'Ride Started';
        message.content = `Your ride is returning from ${ride.dropAddress}`;
        message.data.type = 'ride-started';
        break;
      case 'approaching':
        message.title = 'Ride Approaching';
        message.content = `Your ride is approaching ${ride.nearLocation.address}`; // assuming 'nearLocation' is the next location in the route
        message.data.type = 'ride-approaching';
        break;
      case 'arrived-onward':
        message.title = 'Ride Arrived';
        message.content = `Your ride has arrived at ${ride.dropAddress}`;
        message.data.type = 'ride-arrived';
        break;
      case 'arrived-return':
        message.title = 'Ride Arrived';
        message.content = `Your ride has returned to ${ride.pickupAddress}`;
        message.data.type = 'ride-arrived';
        break;
      default:
        return;
    }

    this.notificationService.messageCreate(message);
  }

}
