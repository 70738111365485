import { createAction, props } from '@ngrx/store';
import { User, Credentials } from '@app/pages/models/user';


// Login
export const login = createAction(
  '[Login Form Page] Login',
  props<{ credentials: Credentials }>()
);

// Login API
export const loginSuccess = createAction(
  '[Auth/API] Login Success',
  props<{ user: any }>()
);

export const loginFailure = createAction(
  '[Auth/API] Login Failure',
  props<{ error: any }>()
);

export const loginRedirect = createAction(
  '[Auth/API] Login Redirect'
);


// Logout
export const logout = createAction(
  '[Auth] Logout'
);

export const logoutSuccess = createAction(
  '[Auth] Logout Success'
);

export const logoutFailure = createAction(
  '[Auth] Logout Failure',
  props<{ error: any }>()
);


// Login User Upsert
export const loginUserUpsert = createAction(
  '[Login] Login User Upsert',
  props<{ user: any }>()
);

export const loginUserUpsertSuccess = createAction(
  '[Login] Login User Upsert Success'
);

export const loginUserUpsertFailure = createAction(
  '[Login] Login User Upsert Failure',
  props<{ error: any }>()
);
