import { createReducer, on } from '@ngrx/store';
import { Ride } from '@app/pages/models';
import { RidePageActions } from '@app/pages/actions';

export const ridePageFeatureKey = 'ridePage';

export interface State {
  bookedRides: Ride[];
  dropOffQuery: string;
  error: string | null;
  loading: boolean;
  offeredRides: Ride[];
  pending: boolean;
  pickUpQuery: string;
  rideHistory: any;
  searchRides: string[];
  selectedRide: any;
  startTimeQuery: string;
}

export const initialState: State = {
  bookedRides: [],
  dropOffQuery: '',
  error: null,
  loading: false,
  offeredRides: [],
  pending: false,
  pickUpQuery: '',
  rideHistory: [],
  searchRides: [],
  selectedRide: {},
  startTimeQuery: '',
};

export const reducer = createReducer(
  initialState,

  on(RidePageActions.rideUpsert, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),

  on(RidePageActions.rideUpsertSuccess, (state) => ({
    ...state,
    error: null,
    pending: false,
  })),

  on(RidePageActions.rideUpsertFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(RidePageActions.rideGet, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),

  on(RidePageActions.rideGetSuccess, (state) => ({
    ...state,
    error: null,
    pending: false,
  })),

  on(RidePageActions.rideGetFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(RidePageActions.bookRide, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),

  on(RidePageActions.bookRideSuccess, (state) => ({
    ...state,
    error: null,
    pending: false,
  })),

  on(RidePageActions.bookRideFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(RidePageActions.getAllRidesFilter, (state) => ({
    ...state,
    error: null,
    pending: true,
  })),

  on(RidePageActions.getAllRidesFilterSuccess, (state, { bookedRides, offeredRides, rideHistory }) => ({
    ...state,
    bookedRides,
    offeredRides,
    rideHistory,
    error: null,
    pending: false,
  })),

  on(RidePageActions.getAllRidesFilterFailure, (state, { error }) => ({
    ...state,
    error,
    pending: false,
  })),

  on(RidePageActions.searchRide, (state, { query }) => {
    return query === ''
      ? {
        ...state,
        searchRides: [],
        loading: false,
        error: '',
        query,
      }
      : {
        ...state,
        loading: true,
        error: '',
        pickUpQuery: state.pickUpQuery,
        dropOffQuery: state.dropOffQuery,
        startTimeQuery: state.startTimeQuery,
      };
  }),

  on(RidePageActions.filterSearchResultsSuccess, (state, { rides }) => ({
    ...state,
    searchRides: rides,
    loading: false,
  })),

  on(RidePageActions.filterSearchResultsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),

  on(RidePageActions.selectRide, (state, { ride }) => ({
    ...state,
    selectedRide: ride,
    loading: false,
    error: '',
  }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getOfferedRides = (state: State) => state.offeredRides;
export const getBookedRides = (state: State) => state.bookedRides;

export const getPickUpQuery = (state: State) => state.pickUpQuery;
export const getDropOffQuery = (state: State) => state.dropOffQuery;
export const getStartTimeQuery = (state: State) => state.startTimeQuery;
export const getSelectedRide = (state: State) => state.selectedRide;
export const getRideHistory = (state: State) => state.rideHistory;
export const getSearchRides = (state: State) => state.searchRides;







