import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Event } from '@app/pages/models/calendar';
import { from, Observable, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { CalendarActions } from '@app/pages/actions';


@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    event: Event;
    private createdTime = new Date().toISOString();
    private startDate = new Date().toISOString();
    private endDate = new Date().toISOString();
    private id = 'id123';
    private summary = 'summary123';
    private location = 'location123';
    private description = 'description123';
    private calendarData = [
        'data:text/calendar;charset=utf8,',
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'DESCRIPTION:' + this.description,
        'DTSTART:' + this.startDate,
        'DTEND:' + this.endDate,
        'LOCATION:' + this.location,
        'SUMMARY:' + this.summary,
        'TRANSP:TRANSPARENT',
        'END:VEVENT',
        'END:VCALENDAR',
        'UID:' + this.id,
        'DTSTAMP:' + this.createdTime,
        'PRODID:website-1.0'
    ].join('\n');

    constructor(
        private firestore: AngularFirestore,
        public ngFireAuth: AngularFireAuth,
        private store: Store<fromPages.State>,
    ) {

    }
    eventCreate(event): Observable<any> {
        const newEvent: any = {
            start: [2023, 11, 11, 6],
            duration: { hours: 2, minutes: 0 },
            title: 'HereThere Ride',
            description: event.description,
            location: event.pickupAddress,
            url: 'http://app.herethere.page/',
            geo: { lat: event.pickupLocation.latitude, lon: event.pickupLocation.longitude },
            categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
            status: 'CONFIRMED',
            busyStatus: 'BUSY',
            organizer: { name: event.profile.displayName, email: event.profile.userEmail },
        }

        // ics.createEvent(newEvent, (error, value) => {
        //     if (error) {
        //         console.log(error)
        //         return
        //     }

        //     console.log(value)
        //     window.open('data:text/calendar;charset=utf8,' + value);


        // })


        return from(this.firestore.collection('events').add(event));
    }

    getEvent(id): Observable<any> {
        return this.firestore.doc(id).snapshotChanges()
            .pipe(
                map((doc) => {
                    return doc.payload.data();
                }),
            );
    }

    getEventAll(): Observable<any> {
        return this.firestore.collection('events').valueChanges({ idField: 'id' });
    }

    deleteEvent(id): Observable<void> {
        return from(this.firestore.collection("events").doc(id).delete());
    }
}

