// Rides
export class RideClass {
    bookedByUsers: {
        onwardRide: any[];
        returnRide: any[];
    };
    cancelled: boolean;
    description: any;
    dropAddress: any;
    dropLocation: {
        accuracy: number;
        latitude: number;
        longitude: number;
        timestamp: number;
    };
    offeredByUser: string;
    onwardRideDetails: any;
    pickupAddress: string;
    pickupLocation: {
        accuracy: number;
        latitude: number;
        longitude: number;
        timestamp: number;
    };
    returnRideDetails: any;
    seatsAvailable: {
        onwardRide: any;
        returnRide: any;
    };
    stopovers: any[];
    uid?: string;
    updatedAt: any;

    constructor() {
        this.bookedByUsers = {
            onwardRide: [],
            returnRide: []
        };
        this.cancelled = null;
        this.description = '';
        this.dropAddress = '';
        this.dropLocation = {
            accuracy: null,
            latitude: null,
            longitude: null,
            timestamp: null,
        };
        this.offeredByUser = '';
        this.onwardRideDetails = {};
        this.pickupAddress = '';
        this.pickupLocation = {
            accuracy: null,
            latitude: null,
            longitude: null,
            timestamp: null,
        };
        this.returnRideDetails = {};
        this.seatsAvailable = {
            onwardRide: null,
            returnRide: null,
        };
        this.stopovers = [];
        this.uid = ' ';
    }
};


export interface Ride extends RideClass { }
export type RidePropsArray = Array<keyof Ride>
