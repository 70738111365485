import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Ride, RideClass } from '@app/pages/models/ride';
import { from, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, take } from 'rxjs/operators';
import { UserClass } from '@app/pages/models/user'
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { FirestoreService } from '@app/pages/services/firestore.service';
import { UtilService } from '@app/pages/services/util.service';


type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class RideService {
  private rideData: Ride;
  private user;
  private userData;

  constructor(
    private firestore: AngularFirestore,
    private store: Store<fromPages.State>,
    public ngFireAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private utilService: UtilService,
  ) {
    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        if (value) {
          const newUser = new UserClass();
          const keys = Object.keys(newUser);;
          keys.forEach((prop: any) => {
            newUser[prop] = value[prop] || null;
          })
          this.user = { ...newUser };
        }
      });
  }

  upsertRide(data): Observable<void> {
    try {
      const newRide = new RideClass();
      const keys = Object.keys(newRide);;
      keys.forEach((prop: any) => {
        newRide[prop] = data[prop] ? data[prop] : newRide[prop];
      })
      if (newRide.offeredByUser == '') {
        newRide.offeredByUser = this.user.uid;
      }
      newRide.seatsAvailable = { onwardRide: this.utilService.calculateSeatsAvailable(data, 'onwardRide'), returnRide: this.utilService.calculateSeatsAvailable(data, 'returnRide') };
      let offeredRide: any = { ...newRide, id: data.id };

      const doc = this.firestore.collection('rides').doc(offeredRide.id)
        .snapshotChanges()
        .pipe(take(1))
        .toPromise()

      return from(doc.then((snap): any => {
        return snap.payload.exists ? this.firestore.collection('rides').doc(offeredRide.id).update(offeredRide) : this.firestore.collection('rides').add(offeredRide);
      }));
    } catch (error) {
      return error;
    }
  }

  rideGet(): Observable<any> {
    let ride: any;

    return this.firestore.collection('rides', ref =>
      ref.where("id", "==", this.user.uid)
    ).get()
      .pipe(map((doc) => {
        doc.forEach((item) => {
          ride = item.data();
        });
        return ride;
      }));
  }

  rideGetAll(): Observable<any> {
    return this.firestore.collection('rides').valueChanges({ idField: 'id' });
  }

  deleteRide(ride: any): Observable<any> {
    let cancelledRide = { ...ride, cancelled: true }
    return this.upsertRide(cancelledRide);
  }

  cancelRide(data): Observable<any> {
    let ride = data.bookedRide;
    let newBookedRide;
    let onwardRide;
    let bookedByUsers;
    let user = data.user;
    let indexMatchUser;

    if (this.findUserInBookedRides(ride, user)) {
      indexMatchUser = ride.bookedByUsers.onwardRide.indexOf(user.uid);
      onwardRide = [...ride.bookedByUsers.onwardRide];
      onwardRide.splice(indexMatchUser, 1);
      bookedByUsers = { onwardRide, returnRide: ride.bookedByUsers.returnRide }
      newBookedRide = { ...ride, bookedByUsers };
    }
    let rideCopy = this.utilService.shallowCopyObject(newBookedRide, RideClass);

    return from(this.firestore.collection('rides').doc(newBookedRide.id).update(rideCopy));
  }

  getAllRidesFilter(rides): Observable<any> {
    let bookedRides = [];
    let offeredRides = [];
    let currentTime = new Date();
    let rideHistory = [];
    let rideTime;
    let foundUser: boolean = false;

    const isItemAlreadyIncluded = function (rides, item) {
      if (rides.length > 0) {
        rides.every((ride) => {
          if (ride.id != item.id) {
            rides.push(item);
            return false;
          }
        });
      } else {
        rides.push(item);
        return false;
      }
    }

    rides.forEach((ride, index) => {
      rideTime = new Date(ride.onwardRideDetails.startDate + ', ' + ride.onwardRideDetails.startTime);

      if (!ride.bookedByUsers) {
        ride = { ...ride, bookedByUsers: [] };
      }

      if (ride.offeredByUser == this.user.uid) {
        if (rideTime < currentTime || ride.cancelled) {
          isItemAlreadyIncluded(rideHistory, ride);
        } else {
          isItemAlreadyIncluded(offeredRides, ride);
        }
      } else {
        foundUser = false;

        ride.bookedByUsers.onwardRide.every((item) => {
          foundUser = this.findUserInBookedRides(ride, this.user);
        })

        if (foundUser && rideTime < currentTime && ride.cancelled) {
          isItemAlreadyIncluded(rideHistory, ride);
        } else if (foundUser) {
          isItemAlreadyIncluded(bookedRides, ride);
        }
      }
    })

    const sortRides = (rides: any, sortOrder) => {
      return rides.sort(
        (objA, objB) => {
          let dateA: any = new Date(objA.onwardRideDetails.startDate);
          let dateB: any = new Date(objB.onwardRideDetails.startDate);
          let order = sortOrder ? (dateA - dateB) : (dateA + dateB)
          return order;
        },
      );
    }

    const sortedBookedRides = sortRides(bookedRides, true);
    const sortedOfferedRides = sortRides(offeredRides, true);
    const sortedRideHistory = sortRides(rideHistory, false);

    return of({ bookedRides: [...sortedBookedRides], offeredRides: [...sortedOfferedRides], rideHistory: [...sortedRideHistory] });
  }

  filterRideResults(rides): Observable<any> {
    let currentTime = new Date();
    let rideTime;
    let copySearchRides = [];

    try {
      if (rides.length > 0 && this.user) {
        rides.forEach((ride, index) => {
          rideTime = new Date(ride.onwardRideDetails.startDate + ', ' + ride.onwardRideDetails.startTime)
          if (rideTime > currentTime) {
            if (!copySearchRides.includes(ride.id) && (ride.offeredByUser != this.user.uid) && !ride.bookedByUsers.onwardRide.includes(this.user.uid) && !ride.cancelled) {
              copySearchRides.push(ride);
            }
          }
        })

        const sortRides = (rides: any, sortOrder) => {
          return rides.sort(
            (objA, objB) => {
              let dateA: any = new Date(objA.onwardRideDetails.startDate + ', ' + objA.onwardRideDetails.startTime);
              let dateB: any = new Date(objB.onwardRideDetails.startDate + ', ' + objB.onwardRideDetails.startTime);
              let order = sortOrder ? (dateA - dateB) : (dateA + dateB)
              return order;
            },
          );
        }

        const sortedCopySearchRides = sortRides(copySearchRides, true);
        rides = [...sortedCopySearchRides];
      }

      return of({ rides })
    }
    catch (error) {
      console.log(error);
    }
  }

  findUserInBookedRides(ride, user) {
    let foundUser: boolean = false;

    ride.bookedByUsers.onwardRide.every((item) => {
      if (item.id == user.uid) {
        foundUser = true;
      }
    })
    return foundUser;
  }

}