import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, take, tap } from 'rxjs/operators';
import { Credentials } from '@app/pages/models';
import { EditProfilePageActions, RidePageActions, CarActions } from '@app/pages/actions';
import { Injectable } from '@angular/core';
import { LoginFormPageActions, UserPageActions } from '@app/pages/actions';
import { LoginService } from '@app/pages/services/login.service';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';


@Injectable()
export class LoginFormEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFormPageActions.login),
      map(action => action.credentials),
      exhaustMap((auth: Credentials) =>
        this.loginService.SignIn(auth).pipe(
          map((user: any) => LoginFormPageActions.loginSuccess({ user })),
          catchError(error => of(LoginFormPageActions.loginFailure({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFormPageActions.logout),
      map(action => action),
      exhaustMap(() =>
        this.loginService.Logout().pipe(
          map(() => LoginFormPageActions.logoutSuccess()),
          catchError((error: any) => of(LoginFormPageActions.logoutFailure(error)))
        )
      )
    )
  );

  setLoggedInBoolean$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginFormPageActions.loginSuccess),
      tap((action) => {
        this.store.dispatch(UserPageActions.userGetSuccess({ user: action.user }));
        this.store.dispatch(UserPageActions.userGetAll());
        this.store.dispatch(EditProfilePageActions.profileGetAll());
        this.store.dispatch(EditProfilePageActions.profileGet());
        this.store.dispatch(RidePageActions.rideGetAll());
        this.store.dispatch(CarActions.carGetAll());
        this.router.navigate(['/tabs/home']);
      }),
      take(1))
  );

  checkVerifiedEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginFormPageActions.loginSuccess),
        tap((user) => this.loginService.checkVerifiedEmail(user))
      ),
    { dispatch: false }
  );

  // loginRedirect$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(LoginFormPageActions.loginRedirect, LoginFormPageActions.logout),
  //       tap(authed => {
  //         this.router.navigate(['/login']);
  //       })
  //     ),
  //   { dispatch: false }
  // );


  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private router: Router,
    private store: Store<fromPages.State>,
  ) { }
}
