
<google-map *ngIf="directionsResults$ | async as directionsResults" (mapInitialized)="onMapReady($event)" [center]="center" [zoom]="zoom" [waypoints]="waypoints" height="50vh" width="100vw">
  <!-- <pre> 
    <code>
       {{ markers | json }}
    </code>
  </pre> -->
    <map-directions-renderer [directions]="directionsResults" />
  <!-- <google-map-overlay *ngFor="let stopover of waypoints" latitude="stopover.location.lat"
    longitude="stopover.location.lng">
    <div class="overlayStyle">
      S</div>
  </google-map-overlay> -->
    <!-- <map-marker *ngFor="let marker of markers" [position]="marker.center" iconUrl="marker.icon" agmFitBounds="true">
  </map-marker> -->
  <map-marker *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options"></map-marker>
  <!-- <google-map origin="pickupLocation" destination="dropLocation" markerOptions="markerOptions"
    waypoints="waypoints">
  </google-map> -->
</google-map>
