import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../services/user.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RoleFormService } from '@app/pages/services/role-form.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';
import { EditProfilePageActions, UserPageActions, LoginFormPageActions } from '@app/pages/actions';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.page.html',
  styleUrls: ['./role-form.page.scss']
})
export class RoleFormPage implements OnInit {
  user: any;
  role = null;
  form = new FormGroup({
    role: new FormControl(''),
  });
  title$: Observable<string>;
  user$: Observable<{}>;

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private userForm: RoleFormService,
    private store: Store<fromPages.State>,
  ) { }

  ngOnInit() {
    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        if (value) {
          this.user = value;
        }
      });

    this.title$ = this.userForm.title$;
    this.user$ = this.userForm.user$.pipe(
      tap(user => {
        if (user) {
          this.form.patchValue(user);
        } else {
          this.form.reset({});
        }
      })
    );
  }

  onChange(event) {
    this.role = event.detail.value;
  }

  async dismiss() {
    await this.modalController.dismiss('Cancel');
  }

  async save() {
    const roleUser = { displayName: 'smarkle', email: this.user.email, role: this.role, password: 'Doggone', id: this.user.uid };
    await this.modalController.dismiss({ ...roleUser });
  }

}
