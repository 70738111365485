import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { FirestoreService } from '@app/pages/services/firestore.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { RidePageActions } from '@app/pages/actions';
import { RideService } from '@app/pages/services/ride.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';


@Injectable()
export class RideEffects {
  upsertRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.rideUpsert),
      map(action => action.ride),
      exhaustMap((ride: any) =>
        this.rideService.upsertRide(ride).pipe(
          map(() => RidePageActions.rideUpsertSuccess()),
          catchError(error => of(RidePageActions.rideUpsertFailure({ error })))
        )
      )
    )
  );

  rideGetAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.rideGetAll),
      map(action => action),
      exhaustMap(() =>
        this.rideService.rideGetAll().pipe(
          map((rides: any) => RidePageActions.rideGetAllSuccess({ rides })),
          catchError(error => of(RidePageActions.rideGetAllFailure({ error })))
        )
      )
    )
  );

  deleteRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.rideDelete),
      map(action => action.ride),
      exhaustMap((ride: any) =>
        this.rideService.deleteRide(ride).pipe(
          map(() => RidePageActions.rideDeleteSuccess()),
          catchError(error => of(RidePageActions.rideDeleteFailure({ error })))
        )
      )
    )
  );

  searchRideSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.searchRideSuccess),
      map(action => action.rides),
      exhaustMap((rides: any) =>
        this.rideService.filterRideResults(rides).pipe(
          map((rides) => RidePageActions.filterSearchResultsSuccess(rides)),
          catchError(error => of(RidePageActions.filterSearchResultsFailure({ error })))
        )
      )
    )
  );

  bookRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.bookRide),
      map(action => action.ride),
      exhaustMap((ride: any) =>
        this.rideService.upsertRide(ride).pipe(
          map(() => RidePageActions.bookRideSuccess()),
          catchError(error => of(RidePageActions.bookRideFailure({ error })))
        )
      )
    )
  );

  rideGetAllSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.rideGetAllSuccess),
      map(action => action.rides),
      exhaustMap((rides: any) =>
        this.rideService.getAllRidesFilter(rides).pipe(
          map((rides) => { return { bookedRides: rides.bookedRides, offeredRides: rides.offeredRides, rideHistory: rides.rideHistory } }),
          map((rides) => RidePageActions.getAllRidesFilterSuccess({ ...rides } as any)),
          catchError(error => of(RidePageActions.getAllRidesFilterFailure({ error })))
        )
      )
    )
  );

  upsertRideSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RidePageActions.rideUpsertSuccess),
        tap(() => {
          this.store.dispatch(RidePageActions.rideGetAll());
          this.router.navigate(['/tabs/home']);
        })
      ),
    { dispatch: false }
  );

  cancelRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RidePageActions.cancelRide),
      map(action => action.data),
      exhaustMap((data: any) =>
        this.rideService.cancelRide(data).pipe(
          map(() => RidePageActions.cancelRideSuccess()),
          catchError(error => of(RidePageActions.cancelRideFailure({ error })))
        )
      )
    )
  );

  cancelRideSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RidePageActions.cancelRideSuccess),
        tap(() => {
          this.store.dispatch(RidePageActions.rideGet());
          this.router.navigate(['/tabs/home']);
        })
      ),
    { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private firestoreService: FirestoreService,
    private rideService: RideService,
    private router: Router,
    private store: Store<fromPages.State>,
  ) { }
}
