<ion-header>
  <ion-toolbar>
    <ion-title>{{ title$ | async}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>

    <form *ngIf="user$ | async" #form="ngForm" novalidate>
      <ion-list-header class="ion-text-capitalize">
        <ion-label>Role</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-select placeholder="Please select role" [(ngModel)]="role" name="role" (ionChange)="onChange($event)">
          <ion-select-option value="admin">Admin</ion-select-option>
          <ion-select-option value="manager">Manager</ion-select-option>
          <ion-select-option value="user">User</ion-select-option>
        </ion-select>
      </ion-item>

    </form>

  </ion-grid>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-button (click)="save()" type="submit">Save</ion-button>

      <ion-button (click)="dismiss()">Cancel</ion-button>
    </ion-col>
  </ion-row>
</ion-content>



<!-- 

<ng-container *ngIf="user$ | async"></ng-container>
<div class="modal-header">
  <h4 class="modal-title"
      id="modal-title">{{ title$ | async}}</h4>
  <button type="button"
          class="close"
          aria-describedby="modal-title"
          (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form"
        (ngSubmit)="save()">
    <div class="form-group">
      <label>Email address</label>
      <input type="email"
             formControlName="email"
             class="form-control"
             placeholder="Enter email">
    </div>
    <div class="form-group">
      <label>Password</label>
      <input type="password"
             formControlName="password"
             class="form-control"
             placeholder="Password">
    </div>
    <div class="form-group">
      <label>Display Name</label>
      <input type="string"
             formControlName="displayName"
             class="form-control"
             placeholder="Enter display name">
    </div>
    <div class="form-group">
      <label>Role</label>
      <select class="custom-select"
              formControlName="role">
        <option value="admin">Admin</option>
        <option value="manager">Manager</option>
        <option value="user">User</option>
      </select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-danger"
          (click)="dismiss()">Cancel</button>
  <button type="button"
          class="btn btn-primary"
          (click)="save()">Save</button>
</div> -->