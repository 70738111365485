import { CarActions } from '@app/pages/actions';
import { createReducer, on } from '@ngrx/store';
import { Car } from '@app/pages/models';

export const carFeatureKey = 'carPage';

export interface State {
    car: any;
    cars: any[];
    error: any;
    pending: boolean;
}

export const initialState: State = {
    car: null,
    cars: null,
    error: null,
    pending: false,
};

export const reducer = createReducer(
    initialState,
    on(CarActions.carUpdate, (state) => ({
        ...state,
        error: null,
        pending: true,
    })),

    on(CarActions.carUpdateSuccess, (state, { car }) => ({
        ...state,
        car: car,
        error: null,
        pending: false,
    })),

    on(CarActions.carUpdateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.carCreateSuccess, (state) => ({
        ...state,
        error: null,
        pending: false,
    })),

    on(CarActions.carCreateFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.carGetSuccess, (state, { car }) => ({
        ...state,
        car: car,
        error: null,
        pending: false,
    })),

    on(CarActions.carGetFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.carGetAllSuccess, (state, { cars }) => ({
        ...state,
        cars: cars,
        error: null,
        pending: false,
    })),

    on(CarActions.carGetAllFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    })),

    on(CarActions.carDeleteSuccess, (state) => ({
        ...state,
        error: null,
        pending: false,
    })),

    on(CarActions.carDeleteFailure, (state, { error }) => ({
        ...state,
        error,
        pending: false,
    }))
);

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
export const getUserCar = (state: State) => state.car;
export const getAllCars = (state: State) => state.cars;


