import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, createUrlTreeFromSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginFormPageActions } from '@app/pages/actions';
import * as fromPages from '@app/pages/reducers/';
import { Router } from '@angular/router';
import { LoginService } from '@app/pages/services/login.service';



export const authGuard = (next: ActivatedRouteSnapshot) => {
  return inject(LoginService).canActivate();
};

// @Injectable({
//   providedIn: 'root',
// })
// export class AuthGuard {
//   constructor(private store: Store<fromPages.State>) {}

//   canActivate(): Observable<boolean> {
//     return this.store.pipe(
//       select(fromPages.selectLoggedIn),
//       map(authed => {
//         if (!authed) {
//           this.store.dispatch(LoginFormPageActions.loginRedirect());
//           return false;
//         }

//         return true;
//       }),
//       take(1)
//     );
//   }
// }

// import { inject } from '@angular/core';
// import {
//   ActivatedRouteSnapshot,
//   createUrlTreeFromSnapshot,
// } from '@angular/router';
// import { map } from 'rxjs';
// import { UserService } from './user.service';

// export const authGuard = (next: ActivatedRouteSnapshot) => {
//   return inject(UserService)
//     .isLoggedIn()
//     .pipe(
//       map((isLoggedIn) =>
//         isLoggedIn ? true : createUrlTreeFromSnapshot(next, ['/', 'login'])
//       )
//     );
// };