import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
} from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import * as fromRoot from '@app/reducers';
import * as fromLoginFormPage from './login-form.reducer';
import * as fromEditProfilePage from './edit-profile.reducer';
import * as fromRidePage from './ride.reducer';
import * as fromUserPage from './user.reducer';
import * as fromCar from './car.reducer';
import * as fromCalendar from './calendar.reducer';

export const pagesFeatureKey = 'pages';

export interface AuthState {
  [fromLoginFormPage.loginFormPageFeatureKey]: fromLoginFormPage.State;
  [fromEditProfilePage.editProfilePageFeatureKey]: fromEditProfilePage.State;
  [fromRidePage.ridePageFeatureKey]: fromRidePage.State;
  [fromUserPage.userPageFeatureKey]: fromUserPage.State;
  [fromCar.carFeatureKey]: fromCar.State;
  [fromCalendar.calendarFeatureKey]: fromCalendar.State;
}

export interface State extends fromRoot.State {
  [pagesFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
  return combineReducers({
    [fromLoginFormPage.loginFormPageFeatureKey]: fromLoginFormPage.reducer,
    [fromEditProfilePage.editProfilePageFeatureKey]: fromEditProfilePage.reducer,
    [fromRidePage.ridePageFeatureKey]: fromRidePage.reducer,
    [fromUserPage.userPageFeatureKey]: fromUserPage.reducer,
    [fromCar.carFeatureKey]: fromCar.reducer,
    [fromCalendar.calendarFeatureKey]: fromCalendar.reducer,
  })(state, action);
}

export const selectAuthState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);



// Login
export const selectLoginFormPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginFormPage
);
export const selectLoginFormPageError = createSelector(
  selectLoginFormPageState,
  fromLoginFormPage.getError
);
export const selectLoginFormPagePending = createSelector(
  selectLoginFormPageState,
  fromLoginFormPage.getPending
);

export const selectLoggedIn = createSelector(
  selectLoginFormPageState,
  fromLoginFormPage.getIsLoggedIn
);


// Profile
export const selectEditProfileState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);
export const selectEditProfilePageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.editProfilePage
);

export const selectProfileData = createSelector(
  selectEditProfilePageState,
  fromEditProfilePage.getProfile
);

export const selectProfileIsPending = createSelector(
  selectEditProfilePageState,
  fromEditProfilePage.getPending
);


// Ride
export const selectRideState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);
export const selectRidePageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.ridePage
);

export const selectOfferedRidesData = createSelector(
  selectRidePageState,
  fromRidePage.getOfferedRides
);

export const selectBookedRidesData = createSelector(
  selectRidePageState,
  fromRidePage.getBookedRides
);

export const selectPickUpQuery = createSelector(
  selectRidePageState,
  fromRidePage.getPickUpQuery
);

export const selectDropOffQuery = createSelector(
  selectRidePageState,
  fromRidePage.getDropOffQuery
);

export const selectStartTimeQuery = createSelector(
  selectRidePageState,
  fromRidePage.getStartTimeQuery
);

export const selectSelectedRide = createSelector(
  selectRidePageState,
  fromRidePage.getSelectedRide
);

export const selectRideHistory = createSelector(
  selectRidePageState,
  fromRidePage.getRideHistory
);

export const selectSearchRides = createSelector(
  selectRidePageState,
  fromRidePage.getSearchRides
);


// User
export const selectUserState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);
export const selectUserPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.userPage
);

export const selectUser = createSelector(
  selectUserPageState,
  fromUserPage.getUser
);

export const selectAllUsers = createSelector(
  selectUserPageState,
  fromUserPage.getAllUsers
);

export const selectNotificationSettings = createSelector(
  selectUserPageState,
  fromUserPage.getSettings
);

export const selectAllProfiles = createSelector(
  selectEditProfilePageState,
  fromEditProfilePage.getProfileAll
);


// Car
export const selectCarState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);

export const selectCarPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.carPage
);

export const selectUserCar = createSelector(
  selectCarPageState,
  fromCar.getUserCar
);

export const selectAllCars = createSelector(
  selectCarPageState,
  fromCar.getAllCars
);


// Calendar
export const selectCalendarState = createFeatureSelector<State, AuthState>(
  pagesFeatureKey
);

export const selectCalendarPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.calendarPage
);

export const selectEvent = createSelector(
  selectCalendarPageState,
  fromCalendar.getEvent
);

export const selectAllCalendars = createSelector(
  selectCalendarPageState,
  fromCalendar.getAllEvents
);