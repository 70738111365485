
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GoogleMap, MapDirectionsRenderer, MapDirectionsService, } from '@angular/google-maps';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'map-direction',
  templateUrl: './map-direction.component.html',
  styleUrls: ['./map-direction.component.scss'],
})
export class MapDirectionComponent implements OnInit {
  public directionsResults$: Observable<google.maps.DirectionsResult | undefined>;
  public directionsResults: any = {};
  public center: google.maps.LatLngLiteral = { lat: null, lng: null };
  @Input() public zoom: any;
  @Input() public origin: any;
  @Input() public destination: any;
  @Input() public markerOptions: any;
  @Input() public renderOptions: any;
  @Input() public stopovers: any;
  @Input() public waypoints: any = [];
  @Input() public markers: any;
  @Input() public fitMarkerBounds: boolean = false;
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap

  showMap = false;
  pickupLocation: any;
  dropLocation: any;
  // private map: GoogleMap;

  constructor(
    private mapDirectionsService: MapDirectionsService
  ) {

  }

  ngOnInit() {
    this.center.lat = (this.origin.center.lat + this.destination.center.lat) / 2;
    this.center.lng = (this.origin.center.lng + this.destination.center.lng) / 2;

    const request: google.maps.DirectionsRequest = {
      destination: { lat: this.destination.center.lat, lng: this.destination.center.lng },
      origin: { lat: this.origin.center.lat, lng: this.origin.center.lng },
      waypoints: this.waypoints,
      travelMode: google.maps.TravelMode.DRIVING
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => {
      console.log('response: ', response.result)
      this.updateFitBounds();

      return response.result;
    }));

    if (this.origin && this.destination) {
      this.showMap = true;
      // this.waypoints =
    }
    this.stopovers.forEach(item => {
      this.waypoints.push({ location: { lat: item.latitude, lng: item.longitude }, stopover: true });
    });
    this.pickupLocation = { lat: this.origin.latitude, lng: this.origin.longitude };
    this.dropLocation = { lat: this.destination.latitude, lng: this.destination.longitude };


    // this.markers.forEach((marker) => {
    //   let newMarker = new google.maps.Marker(marker);
    //   newMarker.addListener("click", () => {
    //     let map = newMarker.get("map");
    //     map.setZoom(8);
    //     map.setCenter(newMarker.getPosition() as google.maps.LatLng);
    //   });
    // })
  }

  public onMapReady(map: google.maps.Map): void {
    // this.map = map;
    // map.fitBounds(this.bounds, 20);

  }

  updateFitBounds() {
    if (this.map) {
      let bounds = new google.maps.LatLngBounds();
      bounds.extend(
        new google.maps.LatLng(this.center.lat, this.center.lng)
      );

      this.map.fitBounds(bounds);
    }
  }

}
