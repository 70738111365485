import { createAction, props } from '@ngrx/store';
import { Event } from '@app/pages/models/calendar';


// Event
export const eventGet = createAction(
    '[Event Page] Get Event',
    props<{ id: any }>()
);

export const eventGetSuccess = createAction(
    '[Event Page] Get Event Success',
    props<{ event: any }>()
);

export const eventGetFailure = createAction(
    '[Event Page] Get Event Failure',
    props<{ error: any }>()
);


// All Events
export const eventGetAll = createAction(
    '[Event Page] Get All Events',
);

export const eventGetAllSuccess = createAction(
    '[Event Page] Get All Events Success',
    props<{ events: any[] }>()
);

export const eventGetAllFailure = createAction(
    '[Event Page] Get All Events Failure',
    props<{ error: any }>()
);


// Event Update
export const eventUpdate = createAction(
    '[Event Page] Event Update',
    props<{ event: any }>()
);

export const eventUpdateSuccess = createAction(
    '[Event Page] Event Update Success',
    props<{ event: any }>()
);

export const eventUpdateFailure = createAction(
    '[Event Page] Event Update Failure',
    props<{ error: any }>()
);


// Create Create
export const eventCreate = createAction(
    '[Event Page] Event Create',
    props<{ event: any }>()
);

export const eventCreateSuccess = createAction(
    '[Event Page] Event Create Success'
);

export const eventCreateFailure = createAction(
    '[Event Page] Event Create Failure',
    props<{ error: any }>()
);

// Event Delete
export const eventDelete = createAction(
    '[Event] Event Delete',
    props<{ id: any }>()
);

export const eventDeleteSuccess = createAction(
    '[Event] Event Delete Success'
);

export const eventDeleteFailure = createAction(
    '[Event] Event Delete Failure',
    props<{ error: any }>()
);
