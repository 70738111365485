import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User, UserClass } from '@app/pages/models/user';
import { from, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    user: User;

    constructor(
        private firestore: AngularFirestore,
        public ngFireAuth: AngularFireAuth,
        private store: Store<fromPages.State>,
    ) {
        this.store.pipe(select(fromPages.selectUser))
            .subscribe(value => {
                if (value) {
                    this.user = { ...value };
                }
            });
    }

    getUser(): Observable<any> {
        return this.firestore.doc(`users/${this.user.uid}`).valueChanges().pipe(
            catchError(error => {
                console.error('Error retrieving user:', error);
                return of(null);
            })
        );
    }

    getUserAll(): Observable<any[]> {
        return this.firestore.collection('users').get().pipe(
            map(querySnapshot => querySnapshot.docs.map(doc => doc.data()))
        );
    }

    updateUser(data): Observable<any> {
        const userRef = this.firestore.doc(`users/${this.user.uid}`);
        const updatedUser = { ...this.user, ...data };

        return from(
            userRef.set(updatedUser, { merge: true }).then(() => updatedUser)
        );
    }

}

