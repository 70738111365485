import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, take, tap } from 'rxjs/operators';
import { CarActions } from '@app/pages/actions';
import { Car } from '@app/pages/models';
import { CarService } from '@app/pages/services/car.service';


@Injectable()
export class CarEffects {

    carCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.carCreate),
            map(action => action.car),
            exhaustMap((car: any) =>
                this.carService.carCreate(car).pipe(
                    map(() => CarActions.carCreateSuccess()),
                    catchError((error) => of(CarActions.carCreateFailure({ error })))
                )
            )
        )
    );

    getCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.carGet),
            map(action => action.id),
            exhaustMap((id) =>
                this.carService.getCar(id).pipe(
                    map((car: any) => CarActions.carGetSuccess({ car })),
                    catchError((error) => of(CarActions.carGetFailure({ error })))
                )
            )
        )
    );

    getCarAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.carGetAll),
            map(action => action),
            exhaustMap(() =>
                this.carService.getCarAll().pipe(
                    map((cars: any[]) => CarActions.carGetAllSuccess({ cars })),
                    catchError((error) => of(CarActions.carGetAllFailure({ error })))
                )
            )
        )
    );

    // carSuccess$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(CarActions.carUpdateSuccess),
    //             // tap(() => this.router.navigate(['/tabs/car']))
    //         ),
    //     { dispatch: false }
    // );

    deleteCar$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarActions.carDelete),
            map(action => action.id),
            exhaustMap((id) =>
                this.carService.deleteCar(id).pipe(
                    map(() => CarActions.carDeleteSuccess()),
                    catchError((error) => of(CarActions.carDeleteFailure({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private carService: CarService,
        private router: Router,
    ) { }
}
