import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import {
  EditProfilePageActions
} from '@app/pages/actions';
import { Profile } from '@app/pages/models';
import { ProfileService } from '@app/pages/services/profile.service';

@Injectable()
export class EditProfileEffects {

  upsertProfile$ = createEffect(() =>
  this.actions$.pipe(
    ofType(EditProfilePageActions.profileUpsert),
    map(action => action.data),
    exhaustMap((profile: any) =>
      this.profileService.upsertProfile(profile).pipe(
        map(() => EditProfilePageActions.profileUpsertSuccess()),
        catchError(error => of(EditProfilePageActions.profileUpsertFailure({ error })))
      )
    )
  )
);

  getProfile$ = createEffect(() =>
  this.actions$.pipe(
    ofType(EditProfilePageActions.profileGet),
    map(action => action),
    exhaustMap(() =>
      this.profileService.getProfile().pipe(
        map((profile:any) => EditProfilePageActions.profileGetSuccess({profile})),
        catchError(error => of(EditProfilePageActions.profileGetFailure({ error })))
      )
    )
  )
);

getProfileAll$ = createEffect(() =>
this.actions$.pipe(
  ofType(EditProfilePageActions.profileGetAll),
  map(action => action),
  exhaustMap(() =>
    this.profileService.getProfileAll().pipe(
      map((profiles:any[]) => EditProfilePageActions.profileGetAllSuccess({profiles})),
      catchError(error => of(EditProfilePageActions.profileGetAllFailure({ error })))
    )
  )
)
);

  // profileSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(EditProfilePageActions.profileUpsertSuccess),
  //       tap(() => this.router.navigate(['/tabs/profile']))
  //     ),
  //   { dispatch: false }
  // );
  

  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private router: Router,
  ) {}
}
