
import { Injectable, OnInit } from '@angular/core';
import { Plugin } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { UserClass } from '@app/pages/models/user';
import * as fromPages from '@app/pages/reducers';
import { CarActions, UserPageActions } from '@app/pages/actions';
import { Observable, of } from 'rxjs';

declare let google;

@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnInit {
  private users = [];
  private user: any;
  private cars: any = [];
  private profiles: any = [];

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private navCtrl: NavController,
    private store: Store<fromPages.State>,
  ) {
    this.store.pipe(select(fromPages.selectAllUsers))
      .subscribe(values => {
        if (values) {
          const newUser = new UserClass();
          const keys = Object.keys(newUser);;
          values.forEach((value) => {
            keys.forEach((prop: any) => {
              newUser[prop] = value[prop] || null;
            })
            this.users.push({ ...newUser });
          })
        }
      });

    this.store.pipe(select(fromPages.selectAllProfiles))
      .subscribe(value => {
        this.profiles = [...value];
      });

    this.store.pipe(select(fromPages.selectUser))
      .subscribe(value => {
        if (value) {
          this.user = value;
        }
      });

    this.store.pipe(select(fromPages.selectAllCars))
      .subscribe(value => {
        if (value) {
          this.cars = [...value];
        }
      });
  }

  ngOnInit() {

  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  goToNew(route) {
    this.navCtrl.navigateRoot(route);
  }

  goBack() {
    this.navCtrl.back();
  }

  goBackTo(route) {
    this.navCtrl.navigateBack(route);
  }

  goForward(route) {
    this.navCtrl.navigateForward(route);
  }

  async createAlert(header, backdropDismiss, message, buttonOptions1, buttonOptions2?): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2 ? [buttonOptions1] : [buttonOptions1, buttonOptions2]
    });
    return alert;
  }

  async createLoader(message): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingCtrl.create({
      message
      // duration: 3000
    });
    return loader;
  }

  async createToast(message, showCloseButton = false, position = 'bottom' as 'top' | 'bottom' | 'middle', duration = 2000): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      message,
      position,
      duration,
      buttons: [{
        text: 'Done',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    return toast;
  }

  async createModal(component, componentProps?, cssClass?, showBackdrop?: true, backdropDismiss?: true): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component,
      cssClass,
      componentProps,
      showBackdrop,
      backdropDismiss
    });
    return modal;
  }

  async createActionSheet(button1, button2?, button3?) {
    const sheet = await this.actionSheetCtrl.create({
      buttons: [button1, button2, button3]
    });

    return sheet;
  }

  alignProfileToRide(rides: any) {
    if (!rides) { return rides }

    if (!Array.isArray(rides)) {
      let matchedProfile;
      this.profiles.map((profile, i) => {
        if (Object.values(profile).includes(rides.offeredByUser)) {
          let alignedProfile = this.alginUserToProfile(profile);
          matchedProfile = { ...alignedProfile };
        }
      })
      rides = { ...rides, profile: matchedProfile };
    } else {
      rides.map((ride, rideIndes) => {
        this.profiles.map((profile, i) => {
          if (Object.values(profile).includes(ride.offeredByUser)) {
            profile = this.alginUserToProfile(profile);
            rides[rideIndes] = { ...ride, profile };
          }
        })
      })
    }
    return rides;
  }

  alginUserToProfile(profiles) {
    let obj;
    let objArray;

    var checkForUser = (profile) => {
      this.users.forEach((user) => {
        if (user.uid == profile.userId) {
          obj = profile = { ...profile, userPhone: user.phoneNumber, userEmail: user.email };
        }
      });
    }
    if (!Array.isArray(profiles)) {
      obj = {};
      checkForUser(profiles);

      return obj;
    } else {
      objArray = [];
      this.profiles.forEach((profile) => {
        checkForUser(profile);
        objArray.push(obj);
      });

      return objArray;
    }
  }

  alignCarToProfile(profile) {
    let foundCar = {};
    this.cars.forEach((car) => {
      if (car.id == profile.selectedCar) {
        foundCar = { ...car };
      }
    })
    return foundCar;
  }
  // async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
  //   const service = new window.google.maps.places.AutocompleteService();
  //   let pred;
  //   // var circle = new google.maps.Circle(
  //   //     {center: geolocation, radius: 10000});
  //   // autocomplete.setBounds(circle.getBounds());
  //   await new Promise((resolve, reject) => {
  //     service.getPlacePredictions({
  //       input: searchText,
  //       componentRestrictions: { country: country || environment.COUNTRY }
  //     }, (predictions) => {
  //       pred = predictions;
  //       resolve(true);
  //     });
  //   });
  //   return pred;
  // }




  changeStatusBarColor() {
    StatusBar.setBackgroundColor({color:'#000'});
  }

  shallowCopyObject(value, typeClass) {
    const newObj = new typeClass();
    const keys = Object.keys(newObj);;
    keys.forEach((prop: any) => {
      newObj[prop] = value[prop] ? value[prop] : newObj[prop];
    })
    return { ...newObj };
  }

  changeTimeFormat(date?) {
    let dateTime = date ? date : new Date();
    let newDate = dateTime.toLocaleString([], {
      hour: '2-digit',
      minute: '2-digit'
    });

    return newDate
  }

  sortRides(rides: any) {
    return rides.sort(
      (objA, objB) => {
        let dateA: any = new Date(objA.onwardRideDetails.startDate);
        let dateB: any = new Date(objB.onwardRideDetails.startDate);
        return dateA - dateB;
      },
    );
  }

  calculateSeatsAvailable(ride, type) {
    let seatsTaken = 0;
    let seatsAvailable = 0;

    if (ride.bookedByUsers) {
      ride.bookedByUsers[type].forEach((bookedUser) => {
        seatsTaken += bookedUser.passengerCount;
      })
    }

    seatsAvailable = ride[type + 'Details'].passengerCount - seatsTaken;

    return seatsAvailable;
  }

}
