import { createAction, props } from '@ngrx/store';
import { Profile } from '@app/pages/models/profile';


// Profile
export const profileGet = createAction(
  '[Profile] Get Profile'
);

export const profileGetSuccess = createAction(
  '[Profile] Profile Get Success',
  props<{ profile: any }>()
);

export const profileGetFailure = createAction(
  '[Profile] Profile Get Failure',
  props<{ error: any }>()
);

// Profile All
export const profileGetAll = createAction(
  '[Profile] Profile Get All'
);

export const profileGetAllSuccess = createAction(
  '[Profile] Profile Get All Success',
  props<{ profiles: any[] }>()
);

export const profileGetAllFailure = createAction(
  '[Profile] Profile Get All Failure',
  props<{ error: any }>()
);

// Profile Upsert
export const profileUpsert = createAction(
  '[Profile] Profile Upsert',
  props<{ data: any }>()
);

export const profileUpsertSuccess = createAction(
  '[Profile] Profile Upsert Success'
);

export const profileUpsertFailure = createAction(
  '[Profile] Profile Upsert Failure',
  props<{ error: any }>()
);
