import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RoleFormPageRoutingModule } from './role-form.routing.module';

import { RoleFormPage } from './role-form.page';
import { GoogleMapsModule } from '@angular/google-maps';
import { ShareableModule } from '@app/components/shareable/shareable.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RoleFormPageRoutingModule,
    GoogleMapsModule,
    ShareableModule
  ],
  declarations: [RoleFormPage]
})
export class RoleFormPageModule {}
