import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RoleFormPage } from '@app/pages/role-form/role-form.page';
import { RoleFormPageModule } from '@app/pages/role-form/role-form.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {
  ShowHidePasswordComponent,
} from '@app/pages/components';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
import { LoginFormEffects, EditProfileEffects, RideEffects, UserEffects, CarEffects, CalendarEffects } from '@app/pages/effects';
import * as fromPages from '@app/pages/reducers';
// import { MaterialModule } from '@app/material';
import { PagesRoutingModule } from './pages-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthTokenHttpInterceptorProvider } from '@app/pages/services/auth-token.interceptor';
import { ShareableModule } from '@app/components/shareable/shareable.module';


export const COMPONENTS = [
  ShowHidePasswordComponent
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule,
    ShareableModule,
    ReactiveFormsModule,
    // MaterialModule,
    // PagesRoutingModule,
    AngularFireAuthGuardModule,
    BrowserAnimationsModule,
    RoleFormPageModule,
    StoreModule.forFeature(fromPages.pagesFeatureKey, fromPages.reducers),
    EffectsModule.forFeature([LoginFormEffects, EditProfileEffects, RideEffects, UserEffects, CarEffects, CalendarEffects]),
  ],
  declarations: COMPONENTS,
  providers: [
    AuthTokenHttpInterceptorProvider,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
