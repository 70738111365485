import { createAction, props } from '@ngrx/store';
import { Car } from '@app/pages/models/car';


// Car
export const carGet = createAction(
    '[Car Page] Get Car',
    props<{ id: any }>()
);

export const carGetSuccess = createAction(
    '[Car Page] Get Car Success',
    props<{ car: any }>()
);

export const carGetFailure = createAction(
    '[Car Page] Get Car Failure',
    props<{ error: any }>()
);


// All Cars
export const carGetAll = createAction(
    '[Car Page] Get All Cars',
);

export const carGetAllSuccess = createAction(
    '[Car Page] Get All Cars Success',
    props<{ cars: any[] }>()
);

export const carGetAllFailure = createAction(
    '[Car Page] Get All Cars Failure',
    props<{ error: any }>()
);


// Car Update
export const carUpdate = createAction(
    '[Car Page] Car Update',
    props<{ car: any }>()
);

export const carUpdateSuccess = createAction(
    '[Car Page] Car Update Success',
    props<{ car: any }>()
);

export const carUpdateFailure = createAction(
    '[Car Page] Car Update Failure',
    props<{ error: any }>()
);


// Create Create
export const carCreate = createAction(
    '[Car Page] Car Create',
    props<{ car: any }>()
);

export const carCreateSuccess = createAction(
    '[Car Page] Car Create Success'
);

export const carCreateFailure = createAction(
    '[Car Page] Car Create Failure',
    props<{ error: any }>()
);

// Car Delete
export const carDelete = createAction(
    '[Car] Car Delete',
    props<{ id: any }>()
);

export const carDeleteSuccess = createAction(
    '[Car] Car Delete Success'
);

export const carDeleteFailure = createAction(
    '[Car] Car Delete Failure',
    props<{ error: any }>()
);
