import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Capacitor } from '@capacitor/core';
import { environment } from '@env/environment';
import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Message, Topic } from '@app/pages/models/notification';
import { Store, select } from '@ngrx/store';
import * as fromPages from '@app/pages/reducers';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private platformMessaging;
  private token;

  constructor(
    private firestore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    private store: Store<fromPages.State>,
    private angularFireMessaging: AngularFireMessaging
  ) {

    if (Capacitor.getPlatform() == 'web') {
      this.platformMessaging = angularFireMessaging;
    } else {
      this.platformMessaging = FirebaseMessaging;
    }

    // this.platformMessaging.messaging.pipe(take(1)).subscribe((messaging) => {
    //   // Use the messaging object directly, as AngularFire wraps around it.
    //   messaging.onMessage = messaging.onMessage.bind(messaging);
    //   messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    // });
  }

  async getToken(): Promise<string> {
    try {
      const options: GetTokenOptions = {
        vapidKey: environment.firebaseConfig.vapidKey,
      };
      const { token } = await FirebaseMessaging.getToken(options);
      console.log(token);
      this.token = token;
      return token; // return the token
    } catch (e) {
      console.error("Failed to get token:", e);
      throw e; // re-throw the error so it can be handled by the caller
    }
  }

  async deleteToken(): Promise<void> {
    try {
      await FirebaseMessaging.deleteToken();
      this.token = '';
      console.log("Token deleted");
    } catch (e) {
      console.error("Failed to delete token:", e);
      throw e; // re-throw the error so it can be handled by the caller
    }
  }

  async messageCreate(message: Message): Promise<any> {
    try {
      const result = await this.firestore.collection('messages').add(message);
      console.log("Message created:", result);
      return result;
    } catch (e) {
      console.error("Failed to create message:", e);
      throw e; // re-throw the error so it can be handled by the caller
    }
  }

  // Topics
  async topicCreate(topic: Topic): Promise<any> {
    try {
      const result = await this.firestore.collection('topics').add(topic);
      console.log("Topic created:", result);
      return result;
    } catch (e) {
      console.error("Failed to create topic:", e);
      throw e; // re-throw the error so it can be handled by the caller
    }
  }

  async subscribeTopic(topic: string): Promise<void> {
    try {
      const token = await this.getToken(); // use the returned token
      await this.platformMessaging.subscribeToTopic(token, topic);
      console.log("Subscribed to topic:", topic);
    } catch (e) {
      console.error("Failed to subscribe to topic:", topic, "Error:", e);
    }
  };

  async unSubscribeTopic(topic: string): Promise<void> {
    try {
      if (!this.token) {
        await this.getToken();
      }
      await this.platformMessaging.unsubscribeFromTopic(this.token, topic);
      console.log("Unsubscribed from topic:", topic);
    } catch (e) {
      console.error("Failed to unsubscribe from topic:", topic, "Error:", e);
    }
  };

  listenToMessages(): any {
    console.log('Listening to messages');
    this.angularFireMessaging.onMessage(payload => {
      alert(payload);
    }).catch((error) => {
      console.error(error);
    }
    );
  }


}

